import moment from 'moment';
import numeral from 'numeral';
import React from 'react';
import { connect } from 'react-redux';

import CadminDashboardsAx from 'app/actions/company-admin/company-dashboards';
import Meta               from 'app/components/common/meta';
import DashboardBuilder   from 'app/components/company-admin/dashboards/builder';
import CadminLayout       from 'app/components/company-admin/layout/';
import Dashboard          from 'app/components/company-dashboards/dashboard';
import PageLoading        from 'app/components/layout/page-loading';
import CadminSlx          from 'app/selectors/company-admin/';
import PageSlx            from 'app/selectors/company-admin/page-dashboard-edit';

class PageCadminDashboardEdit extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onChangeDateRange = this.onChangeDateRange.bind(this);
  }

  onChangeDateRange({startDate, endDate}) {
    const { dashboard, company, setDateRange } = this.props;
    setDateRange(company.id, dashboard.id, startDate, endDate);
  }

  render() {
    const { isLoading, company, dashboard, data } = this.props;
    if (!company) return <PageLoading />;
    const builder = <DashboardBuilder />;

    return (
      <CadminLayout className="page-cadmin-db-edit" company={company} activeItem="dashboard-edit" builder={builder}>
        <Meta title="Edit Dashboard | Millie" />
        <Dashboard {...{isLoading, company, dashboard, data}} onChangeDateRange={this.onChangeDateRange} editMode />
      </CadminLayout>
    );
  }

}

const stateToProps = (state) => ({
  company: CadminSlx.company(state),

  dashboard: PageSlx.dashboard(state),
  data: PageSlx.dashboardData(state),
  isLoading: PageSlx.loadPending(state),
});

const dispatchToProps = (dispatch) => ({
  setDateRange: (companyId, dashboardId, startDate, endDate) => dispatch(CadminDashboardsAx.getData(companyId, dashboardId, {startDate, endDate})),
});

export default connect(stateToProps, dispatchToProps)(PageCadminDashboardEdit);
