import _ from 'lodash';

import { Types }        from 'app/actions/company-admin/builder-dashboard';
import dashboardHelpers from 'app/helpers/company-dashboards';
import utils            from 'app/reducers/utils';

const initialState = {
  companyDashboardId: null,
  hasTouched: false,
  logoPath: null,
  lovedNonprofitIds: [],
  volEventIds: [],
  campaignIds: [],
  employeesCanView: true,
  linkCanView: false,
  showSectionMatch: true,
  showSectionVolunteer: true,
  showSectionGrant: true,
  showSectionGift: true,
  showCustomerGifts: true,
  showEmployeeGifts: true,
  missionText: null,
  programDetailsText: null,
  savePending: false,
  hasSocialFeed: false,
};

const reducer = utils.createReducer(initialState, {

  [Types.SET_DASHBOARD_ID]: (state, {dashboardId}) => {
    return {
      ...initialState,
      companyDashboardId: dashboardId,
    };
  },

  [Types.SET_DASHBOARD]: (state, {dashboard}) => {
    if (dashboard.id !== state.companyDashboardId) return state;
    const attrs = _.pick(dashboard, dashboardHelpers.editAttrs);
    return {
      ...state,
      ...attrs,
    };
  },

  [Types.SET_KEY_VAL]: (state, {key, val}) => {
    return {...state,
      hasTouched: true,
      [key]: val,
    };
  },

  [Types.SET_LOVED_NONPROFIT]: (state, {nonprofit, index}) => {
    const lovedNonprofitIds = [...state.lovedNonprofitIds];
    lovedNonprofitIds[index] = nonprofit && nonprofit.id;
    return {...state,
      hasTouched: true,
      lovedNonprofitIds,
    };
  },
  [Types.REMOVE_LOVED_NONPROFIT]: (state, {index}) => {
    const lovedNonprofitIds = [...state.lovedNonprofitIds];
    lovedNonprofitIds.splice(index, 1);
    return {...state,
      hasTouched: true,
      lovedNonprofitIds,
    };
  },

  [Types.SET_VOL_EVENT]: (state, {volEvent, index}) => {
    const volEventIds = [...state.volEventIds];
    volEventIds[index] = volEvent && volEvent.id;
    return {...state,
      hasTouched: true,
      volEventIds,
    };
  },
  [Types.REMOVE_VOL_EVENT]: (state, {index}) => {
    const volEventIds = [...state.volEventIds];
    volEventIds.splice(index, 1);
    return {...state,
      hasTouched: true,
      volEventIds,
    };
  },

  [Types.SET_CAMPAIGN]: (state, {campaign, index}) => {
    const campaignIds = [...state.campaignIds];
    campaignIds[index] = campaign && campaign.id;
    return {...state,
      hasTouched: true,
      campaignIds,
    };
  },
  [Types.REMOVE_CAMPAIGN]: (state, {index}) => {
    const campaignIds = [...state.campaignIds];
    campaignIds.splice(index, 1);
    return {...state,
      hasTouched: true,
      campaignIds,
    };
  },

  [`${Types.SAVE}_PENDING`]: (state, {dashboardId}) => {
    if (dashboardId !== state.companyDashboardId) return state;
    return {...state,
      savePending: true,
    };
  },
  [`${Types.SAVE}_RESOLVED`]: (state, {dashboardId}) => {
    if (dashboardId !== state.companyDashboardId) return state;
    return {...state,
      savePending: false,
      hasTouched: false,
    };
  },
  [`${Types.SAVE}_REJECTED`]: (state, {dashboardId}) => {
    if (dashboardId !== state.companyDashboardId) return state;
    return {...state,
      savePending: false,
    };
  },

});

export default reducer;
