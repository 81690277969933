import moment from 'moment';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import GroupsAx               from 'app/actions/groups';
import CampaignCard           from 'app/components/common/campaign-card';
import FakeLines              from 'app/components/common/fake-lines';
import Link                   from 'app/components/common/link';
import Icon                   from 'app/components/common/icon';
import ResourceCard           from 'app/components/groups/resource-card';
import NonprofitCard          from 'app/components/nonprofits/nonprofit-card';
import Feed                   from 'app/components/social/feed';
import VolEventCard           from 'app/components/volunteer/vol-event-card';
import {
  USER_DEFAULT_AVATAR_URL,
  SocialFeedTypes,
}                             from 'app/constants';
import slackHelpers           from 'app/helpers/slack';
import format                 from 'app/helpers/format';
import utils                  from 'app/helpers/utils';
import paths                  from 'app/paths';
import AuthSlx                from 'app/selectors/auth';
import GroupsSlx              from 'app/selectors/groups';

const eventLimit = 8;

class GroupProfile extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      expandEvents: false,
      expandCampaigns: false,
    };

    this.onClickLeave = this.onClickLeave.bind(this);
    this.onClickJoin = this.onClickJoin.bind(this);
    this.onClickToggleExpandCampaigns = this.onClickToggleExpandCampaigns.bind(this);
    this.onClickToggleExpandEvents = this.onClickToggleExpandEvents.bind(this);
  }

  get members() {
    const {group, membership, currentUser} = this.props;
    if (!group || !group.members) return [];
    const isMember = membership && membership.isMember;
    if (!isMember) return group.members;
    const alreadyPresent = group.members.some(member => member.userId === currentUser.id);
    if (alreadyPresent) return group.members;
    return [
      {isAdmin: false, userId: currentUser.id, ..._.pick(currentUser, ['firstName', 'lastName', 'avatarUrl'])},
      ...group.members,
    ];
  }

  onClickLeave() {
    const {leave, group} = this.props;
    const isConfirmed = confirm('Are you sure you want to leave this group?');
    if (isConfirmed) leave(group.id);
  }

  onClickJoin() {
    const {join, group} = this.props;
    join(group.id);
  }

  onClickToggleExpandCampaigns() {
    this.setState((prevState) => {
      return {
        expandCampaigns: !prevState.expandCampaigns,
      };
    });
  }

  onClickToggleExpandEvents() {
    this.setState((prevState) => {
      return {
        expandEvents: !prevState.expandEvents,
      };
    });
  }

  renderTitleDesc() {
    const { group, editMode } = this.props;
    const title = group.name
      ? <h1 className="group-pro-title">{group.name}</h1>
      : editMode ? <FakeLines className="group-pro-fl-title" count={1} lineHeight={40} style="full" /> : null;
    const description = group.description
      ? utils.renderTextBlob(group.description, 'group-pro-desc')
      : editMode ? <FakeLines className="group-pro-fl-desc" count={2} lineHeight={16} /> : null;

    return (
      <div className="group-pro-content title">
        {title}
        {description}
      </div>
    );
  }

  renderHighlights() {
    const { group, editMode } = this.props;
    const { highlights = [] } = group;
    if (!highlights.length) return null;

    return (
      <div className="group-pro-content group-pro-highlights">
        {highlights.map((highlight, i) => {
          const imgUrl = highlight.imgPath
            ? utils.s3Url(highlight.imgPath)
            : editMode ? '/images/builder/placeholder-program.png' : null;
          const desc = highlight.text
            ? utils.renderTextBlob(highlight.text)
            : editMode ? <FakeLines /> : null;
          return (
            <div className="group-pro-highlight" key={i}>
              <img className="group-pro-highlight-img" src={imgUrl} />
              <div className="group-pro-highlight-text">{desc}</div>
            </div>
          );
        })}
      </div>
    );
  }

  renderStats() {
    const { group, editMode } = this.props;
    const { stats = [] } = group;
    if (!stats.length) return null;

    return (
      <div className="group-pro-content group-pro-stats">
        {stats.map((stat, i) => {
          const number = stat.number
            ? stat.number
            : editMode ? <FakeLines count={1} style="full" lineHeight={60} /> : null;
          const desc = stat.text
            ? utils.renderTextBlob(stat.text)
            : editMode ? <FakeLines count={2} /> : null;
          return (
            <div className="group-pro-stat" key={i}>
              <div className="group-pro-stat-num">{number}</div>
              <div className="group-pro-stat-text">{desc}</div>
            </div>
          );
        })}
      </div>
    );
  }

  renderMembers() {
    const { group, membership, editMode, membershipIsChanging: isChanging } = this.props;

    return (<>
      <h2 className="group-pro-subheading">Members</h2>
      <div className="group-pro-members-box">
        <div className="group-pro-members-list">
          {editMode ? (
            _.times(4, (i) => {
              return <div key={i} className="group-pro-members-member"><FakeLines style="users" count={4} /></div>;
            })
          ) : (
            this.members.map((member) => {
              return (
                <div key={member.userId} className="group-pro-members-member">
                  <img className="group-pro-members-member-avatar" src={member.avatarUrl || USER_DEFAULT_AVATAR_URL} />
                  <div className="group-pro-members-member-name">{`${member.firstName} ${member.lastName}`}</div>
                  {member.isAdmin && (
                    <div className="group-pro-members-member-lead">Lead</div>
                  )}
                </div>
              );
            })
          )}
        </div>
        <hr />
        {!editMode && (
          <div className="group-pro-members-membership">
            <div className="group-pro-members-membership-count">
              {numeral(group.membersCount || 0).format('0,0')} {format.pluralize('Member', group.membersCount || 0)}
            </div>
            <div className="group-pro-members-membership-status">
              {membership && (membership.isMember ? 'You are a member of this group.' : 'You are not listed as a member of this group on Millie.')}
            </div>
            {membership && (
              membership.isMember
                ? <button className="btn secondary" onClick={this.onClickLeave} disabled={isChanging}>{isChanging ? 'Leaving...' : 'Leave Group'}</button>
                : <button className="btn special groups" onClick={this.onClickJoin} disabled={isChanging}>{isChanging ? 'Joining...' : 'Join Group'}</button>
            )}
          </div>
        )}
      </div>
    </>);
  }

  renderEvents() {
    const {editMode, volEvents} = this.props;
    const {expandEvents: expand} = this.state;
    if (!volEvents?.length) {
      if (editMode) return (<>
        <h2 className="group-pro-subheading">What's Happening</h2>
        <p className="faint italic" style={{textAlign: 'center'}}>Events added to this group will appear here.</p>
      </>);
      return null;
    }
    const showEvents = expand ? volEvents : volEvents.slice(0, eventLimit);
    const showExpand = volEvents.length > eventLimit;

    return (<>
      <h2 className="group-pro-subheading">What's Happening</h2>
      <div className="group-pro-events">
        {showEvents.map((volEvent) => {
          return (
            <div key={volEvent.id} className="group-pro-events-event">
              <VolEventCard volEvent={volEvent} />
            </div>
          );
        })}
      </div>
      {showExpand && (
        <div className="group-pro-expand-con">
          <button onClick={this.onClickToggleExpandEvents} className="btn small blue secondary">{expand ? 'Show Less' : `Show All ${volEvents.length} Events`}</button>
        </div>
      )}
    </>);
  }

  renderCampaigns() {
    const {editMode, campaigns} = this.props;
    const {expandCampaigns: expand} = this.state;
    if (!campaigns?.length) {
      if (editMode) return (<>
        <h2 className="group-pro-subheading">Campaigns</h2>
        <p className="faint italic" style={{textAlign: 'center'}}>Campaigns added to this group will appear here.</p>
      </>);
      return null;
    }
    const showCampaigns = expand ? campaigns : campaigns.slice(0, eventLimit);
    const showExpand = campaigns.length > eventLimit;

    return (<>
      <h2 className="group-pro-subheading">Campaigns</h2>
      <div className="group-pro-campaigns">
        {showCampaigns.map((campaign) => {
          return (
            <div key={campaign.id} className="group-pro-campaigns-campaign">
              <CampaignCard campaign={campaign} />
            </div>
          );
        })}
      </div>
      {showExpand && (
        <div className="group-pro-expand-con">
          <button onClick={this.onClickToggleExpandCampaigns} className="btn small blue secondary">{expand ? 'Show Less' : `Show All ${campaigns.length} Campaigns`}</button>
        </div>
      )}
    </>);
  }

  renderSocial() {
    const {editMode, socialListingIds: listingIds, socialMoreCount: moreCount, group} = this.props;
    if (!group?.hasSocialFeed) return null;

    return (
      <div className="group-pro-social">
        <h2 className="group-pro-subheading">Activity Feed</h2>
        <Feed initialIds={listingIds || []} initialMoreCount={moreCount || 0} type={SocialFeedTypes.GROUP} id={group.id} title={group.name} className="group-pro-social-feed" editMode={editMode} />
      </div>
    );
  }

  renderNonprofits() {
    const { group, editMode } = this.props;
    const { nonprofitIds=[] } = group;
    // add nonprofit card placeholders
    while (editMode && nonprofitIds.length < 3) {
      nonprofitIds.push('');
    }
    if (!nonprofitIds.length) return null;

    return (<>
      <h2 className="group-pro-subheading">Organizations We Love</h2>
      <div className="group-pro-nonprofits">
        {nonprofitIds.map((id, i) => {
          return id
            ? <NonprofitCard id={id} key={id} />
            : <img key={i} src="/images/builder/placeholder-nonprofit.png" className="group-pro-fl-nonprofit" />
        })}
      </div>
    </>);
  }

  renderResources() {
    const { group, editMode } = this.props;
    const { resources = [] } = group;
    if (!resources.length) return null;

    return (<>
      <h2 className="group-pro-subheading">Resources</h2>
      <div className="group-pro-resources">
        {resources.map((resource, i) => {
          return (
            <div key={i} className="group-pro-resources-resource">
              <ResourceCard key={i} editMode={editMode} resource={resource} />
            </div>
          );
        })}
      </div>
    </>);
  }

  render() {
    const { group, editMode, volEvents } = this.props;
    const { slackChannel } = group;
    const bannerStyle = group.bannerImgPath ? {backgroundImage: `url("${utils.s3Url(group.bannerImgPath)}")`} : {};
    const slackUrl = slackChannel
      ? slackHelpers.deepLinkChannel(slackChannel.slackWorkspaceId, slackChannel.slackId)
      : editMode ? '#' : null;
    const logoImgUrl = group.logoImgPath
      ? utils.s3Url(group.logoImgPath)
      : editMode ? '/images/builder/placeholder-logo.png' : null;

    return (
      <div className="group-pro">
        <div className="group-pro-banner" style={bannerStyle} />
        <div className="group-pro-logo-con">
          <div className="group-pro-logo-circle">
            {logoImgUrl && (
              <img className="group-pro-logo-img" src={logoImgUrl} />
            )}
          </div>
        </div>
        <div className="group-pro-padder">
          <div className="group-pro-topline">
            {editMode ? <span /> : (
              <Link href={paths.groups()} className="group-pro-topline-back"><Icon.Caret direction="left" /> Back to Groups</Link>
            )}
            {slackUrl && <a className="btn special groups icon group-pro-topline-slack" href={slackUrl}><Icon.LogoSlack />Go to our Slack Channel</a>}
          </div>
          {this.renderTitleDesc()}
          {this.renderEvents()}
          {this.renderCampaigns()}
          {this.renderSocial()}
          {this.renderHighlights()}
          {this.renderStats()}
          {this.renderMembers()}
          {this.renderResources()}
          {this.renderNonprofits()}
        </div>
      </div>
    );
  }
}

GroupProfile.propTypes = {
  group: PropTypes.object.isRequired,
  membership: PropTypes.object,
  editMode: PropTypes.bool,
  socialListingIds: PropTypes.arrayOf(PropTypes.string),
  socialMoreCount: PropTypes.number,
};

GroupProfile.defaultProps = {
  editMode: false,
  membership: null,
  socialListingIds: null,
  socialMoreCount: null,
};

const stateToProps = (state, ownProps) => ({
  currentUser: AuthSlx.currentUser(state),
  membershipIsChanging: GroupsSlx.membershipIsChanging(state),
  volEvents: GroupsSlx.getVolEvents(state)(ownProps.group),
  campaigns: GroupsSlx.getCampaigns(state)(ownProps.group),
});

const dispatchToProps = (dispatch) => ({
  join: (id) => dispatch(GroupsAx.join(id)),
  leave: (id) => dispatch(GroupsAx.leave(id)),
});

export default connect(stateToProps, dispatchToProps)(GroupProfile);
