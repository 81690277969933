import _ from 'lodash';
import moment from 'moment';
import timm from 'timm';
import { createSelector } from 'reselect';

import AuthAx            from 'app/actions/auth';
import ToastAx           from 'app/actions/toast';
import MillieApi         from 'app/apis/millie';
// import {
//   SocialReactionTargets as ReactionTargets,
// }                        from 'app/constants';
import history           from 'app/history';
import paths             from 'app/paths';
import reducerUtils      from 'app/reducers/utils';
import EntitiesSlx       from 'app/selectors/entities';
import RoutingSlx        from 'app/selectors/routing';



/*
 *  Actions
 */

const Types = {
  LISTINGS_SEARCH: 'SOCIAL_LISTINGS_SEARCH',
  POSTS_CREATE: 'SOCIAL_POSTS_CREATE',
  POSTS_GET: 'SOCIAL_POSTS_GET',
  POSTS_DELETE: 'SOCIAL_POSTS_DELETE',
  POSTS_UPDATE: 'SOCIAL_POSTS_UPDATE',
  REACTIONS_CREATE: 'SOCIAL_REACTIONS_CREATE',
  REACTIONS_DELETE: 'SOCIAL_REACTIONS_DELETE',
  COMMENTS_CREATE: 'SOCIAL_COMMENTS_CREATE',
  COMMENTS_DELETE: 'SOCIAL_COMMENTS_DELETE',
  COMMENTS_UPDATE: 'SOCIAL_COMMENTS_UPDATE',
  COMMENTS_SEARCH: 'SOCIAL_COMMENTS_SEARCH',
};

const Ax = {

  listingsSearch: (params) => (dispatch, getState) => {
    const promise = MillieApi.socialListingsSearch(params);
    return dispatch({type: Types.LISTINGS_SEARCH, promise, _entities: ['socialPostListings', 'socialPosts', 'employees', 'users', 'socialReactionSets']});
  },

  postsCreate: (attrs) => (dispatch, getState) => {
    const promise = MillieApi.socialPostsCreate(attrs);
    promise.then(() => {
      dispatch(ToastAx.success('Posted!'));
    });
    return dispatch({type: Types.POSTS_CREATE, promise, _entities: ['socialPostListing', 'socialPost', 'employee', 'user', 'socialReactionSet']});
  },

  postsGet: (postId) => (dispatch, getState) => {
    const promise = MillieApi.socialPostsGet(postId);
    return dispatch({type: Types.POSTS_GET, promise, _entities: ['socialPostListings', 'socialPost', 'employee', 'user', 'socialReactionSet']});
  },

  postsDelete: (postId) => (dispatch, getState) => {
    const promise = MillieApi.socialPostsDelete(postId);
    promise.then(() => {
      dispatch(ToastAx.success('Post deleted.'));
    });
    return dispatch({type: Types.POSTS_DELETE, promise, _entities: ['socialPost']});
  },

  postsUpdate: (postId, attrs) => (dispatch, getState) => {
    const promise = MillieApi.socialPostsUpdate(postId, attrs);
    promise.then(() => {
      dispatch(ToastAx.success('Post updated.'));
    });
    return dispatch({type: Types.POSTS_UPDATE, promise, _entities: ['socialPostListings', 'socialPost', 'employee', 'user', 'socialReactionSet']});
  },

  reactionsCreate: (targetType, targetId, emojiChar) => {
    const promise = MillieApi.socialReactionsCreate(targetType, targetId, emojiChar);
    return {type: Types.REACTIONS_CREATE, promise, targetId, emojiChar, _entities: ['socialReactionSet']};
  },

  reactionsDelete: (targetType, targetId, emojiChar) => {
    const promise = MillieApi.socialReactionsDelete(targetType, targetId, emojiChar);
    return {type: Types.REACTIONS_DELETE, promise, targetId, emojiChar, _entities: ['socialReactionSet']};
  },

  commentsCreate: (postId, body) => (dispatch, getState) => {
    const promise = MillieApi.socialCommentsCreate(postId, body);
    promise.then(() => {
      dispatch(ToastAx.success('Comment posted.'));
    });
    return dispatch({type: Types.COMMENTS_CREATE, promise, _entities: ['employee', 'user', 'socialPost', 'socialPostComment', 'socialReactionSet']});
  },

  commentsDelete: (commentId) => (dispatch, getState) => {
    const promise = MillieApi.socialCommentsDelete(commentId);
    promise.then(() => {
      dispatch(ToastAx.success('Comment deleted.'));
    });
    return dispatch({type: Types.COMMENTS_DELETE, promise});
  },

  commentsUpdate: (commentId, attrs) => {
    const promise = MillieApi.socialCommentsUpdate(commentId, attrs);
    return {type: Types.COMMENTS_UPDATE, promise, _entities: ['socialPostComment']};
  },

  commentsSearch: (postId, params={}) => {
    const promise = MillieApi.socialCommentsSearch(postId, params);
    return {type: Types.COMMENTS_SEARCH, promise, _entities: ['employees', 'users', 'socialPostComments', 'socialReactionSets']};
  },

};



/*
 *  Reducer
 */

const initialState = {
  postDeletePending: false,
  postUpdatePending: false,
  reactingKeys: {},
  commentCreatePending: false,
  commentUpdatePending: false,
  commentDeletePending: false,
  commentSearchPending: false,
};

const reducer = reducerUtils.createReducer(initialState, {

  [`${Types.POSTS_DELETE}_PENDING`]: (state, action) => {
    return {...state,
      postDeletePending: true,
    };
  },
  [`${Types.POSTS_DELETE}_RESOLVED`]: (state, action) => {
    return {...state,
      postDeletePending: false,
    };
  },
  [`${Types.POSTS_DELETE}_REJECTED`]: (state, action) => {
    return {...state,
      postDeletePending: false,
    };
  },

  [`${Types.POSTS_UPDATE}_PENDING`]: (state, action) => {
    return {...state,
      postUpdatePending: true,
    };
  },
  [`${Types.POSTS_UPDATE}_RESOLVED`]: (state, action) => {
    return {...state,
      postUpdatePending: false,
    };
  },
  [`${Types.POSTS_UPDATE}_REJECTED`]: (state, action) => {
    return {...state,
      postUpdatePending: false,
    };
  },

  [`${Types.REACTIONS_CREATE}_PENDING`]: (state, action) => {
    return {...state,
      reactingKeys: timm.setIn(state.reactingKeys, [action.targetId, action.emojiChar], true),
    };
  },
  [`${Types.REACTIONS_CREATE}_RESOLVED`]: (state, action) => {
    return {...state,
      reactingKeys: timm.setIn(state.reactingKeys, [action.targetId, action.emojiChar], false),
    };
  },
  [`${Types.REACTIONS_CREATE}_REJECTED`]: (state, action) => {
    return {...state,
      reactingKeys: timm.setIn(state.reactingKeys, [action.targetId, action.emojiChar], false),
    };
  },

  [`${Types.REACTIONS_DELETE}_PENDING`]: (state, action) => {
    return {...state,
      reactingKeys: timm.setIn(state.reactingKeys, [action.targetId, action.emojiChar], true),
    };
  },
  [`${Types.REACTIONS_DELETE}_RESOLVED`]: (state, action) => {
    return {...state,
      reactingKeys: timm.setIn(state.reactingKeys, [action.targetId, action.emojiChar], false),
    };
  },
  [`${Types.REACTIONS_DELETE}_REJECTED`]: (state, action) => {
    return {...state,
      reactingKeys: timm.setIn(state.reactingKeys, [action.targetId, action.emojiChar], false),
    };
  },

  [`${Types.COMMENTS_CREATE}_PENDING`]: (state, action) => {
    return {...state,
      commentCreatePending: true,
    };
  },
  [`${Types.COMMENTS_CREATE}_RESOLVED`]: (state, action) => {
    return {...state,
      commentCreatePending: false,
    };
  },
  [`${Types.COMMENTS_CREATE}_REJECTED`]: (state, action) => {
    return {...state,
      commentCreatePending: false,
    };
  },

  [`${Types.COMMENTS_UPDATE}_PENDING`]: (state, action) => {
    return {...state,
      commentUpdatePending: true,
    };
  },
  [`${Types.COMMENTS_UPDATE}_RESOLVED`]: (state, action) => {
    return {...state,
      commentUpdatePending: false,
    };
  },
  [`${Types.COMMENTS_UPDATE}_REJECTED`]: (state, action) => {
    return {...state,
      commentUpdatePending: false,
    };
  },

  [`${Types.COMMENTS_DELETE}_PENDING`]: (state, action) => {
    return {...state,
      commentDeletePending: true,
    };
  },
  [`${Types.COMMENTS_DELETE}_RESOLVED`]: (state, action) => {
    return {...state,
      commentDeletePending: false,
    };
  },
  [`${Types.COMMENTS_DELETE}_REJECTED`]: (state, action) => {
    return {...state,
      commentDeletePending: false,
    };
  },

  [`${Types.COMMENTS_SEARCH}_PENDING`]: (state, action) => {
    return {...state,
      commentSearchPending: true,
    };
  },
  [`${Types.COMMENTS_SEARCH}_RESOLVED`]: (state, action) => {
    return {...state,
      commentSearchPending: false,
    };
  },
  [`${Types.COMMENTS_SEARCH}_REJECTED`]: (state, action) => {
    return {...state,
      commentSearchPending: false,
    };
  },

});



/*
 *  Selectors
 */

const Slx = (() => {

  const selPostDeletePending    = state => state.social.postDeletePending;
  const selPostUpdatePending    = state => state.social.postUpdatePending;
  const selReactingKeys         = state => state.social.reactingKeys;
  const selCommentCreatePending = state => state.social.commentCreatePending;
  const selCommentUpdatePending = state => state.social.commentUpdatePending;
  const selCommentDeletePending = state => state.social.commentDeletePending;
  const selCommentSearchPending = state => state.social.commentSearchPending;

  const selListingSlx = (listingId) => createSelector(
    [EntitiesSlx.socialPostListings, EntitiesSlx.socialPosts, EntitiesSlx.employees, EntitiesSlx.users],
    (listingsMap, postsMap, employeesMap, usersMap) => {
      if (!listingsMap[listingId]) return null;
      const listing = {...listingsMap[listingId]};
      listing.post = {...postsMap[listing.socialPostId]};
      listing.post.employee = employeesMap[listing.post.employeeId];
      listing.post.user = usersMap[listing.post.employee?.userId];
      return listing;
    }
  );

  const selCommentSlx = (commentId) => createSelector(
    [EntitiesSlx.socialPostComments, EntitiesSlx.employees, EntitiesSlx.users],
    (commentsMap, employeesMap, usersMap) => {
      if (!commentsMap[commentId]) return null;
      const comment = {...commentsMap[commentId]};
      comment.employee = employeesMap[comment.employeeId];
      comment.user = usersMap[comment.employee?.userId];
      return comment;
    }
  );

  return {
    listingSlx: selListingSlx,
    commentSlx: selCommentSlx,
    postDeletePending: selPostDeletePending,
    postUpdatePending: selPostUpdatePending,
    reactingKeys: selReactingKeys,
    commentCreatePending: selCommentCreatePending,
    commentUpdatePending: selCommentUpdatePending,
    commentDeletePending: selCommentDeletePending,
    commentSearchPending: selCommentSearchPending,
  };

})();



export {Types, Ax, reducer, Slx};
export default {Types, Ax, reducer, Slx};
