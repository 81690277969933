import React from 'react';
import { connect } from 'react-redux';

import Meta       from 'app/components/common/meta';
import Dashboard  from 'app/components/company-dashboards/dashboard';
import MainLayout from 'app/components/layout/main-layout';
import history    from 'app/history';
import paths      from 'app/paths';
import PageSlx    from 'app/selectors/page-company-dashboard';

class PageCompanyDashboard extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onChangeDateRange = this.onChangeDateRange.bind(this);
  }

  onChangeDateRange({startDate, endDate}) {
    const { dashboard } = this.props;
    const path = paths.companyDashboard(dashboard.id, {startDate, endDate});
    history.push(path);
  }

  render() {
    const { isLoading, company, dashboard, data, socialListingIds, socialMoreCount } = this.props;

    return (
      <MainLayout>
        {company && <Meta title={`${company.name} | Millie`} />}
        <Dashboard {...{isLoading, company, dashboard, data}} onChangeDateRange={this.onChangeDateRange} socialListingIds={socialListingIds} socialMoreCount={socialMoreCount} />
      </MainLayout>
    );
  }

}

const stateToProps = (state) => ({
  isLoading: PageSlx.loadPending(state),
  company: PageSlx.company(state),
  dashboard: PageSlx.dashboard(state),
  data: PageSlx.dashboardData(state),
  socialListingIds: PageSlx.socialListingIds(state),
  socialMoreCount: PageSlx.socialMoreCount(state),
});

const dispatchToProps = (dispatch) => ({
});

export default connect(stateToProps, dispatchToProps)(PageCompanyDashboard);
