import React from 'react';
import { connect } from 'react-redux';

import ClientTimestamp    from 'app/components/common/client-timestamp';
import Meta               from 'app/components/common/meta';
import MainLayout         from 'app/components/layout/main-layout';
import Feed               from 'app/components/social/feed';
import PostCard           from 'app/components/social/post-card';
import {
  SocialFeedTypes,
}                         from 'app/constants';
import ModalPostDuck      from 'app/ducks/modal-social-post-form';
import Duck               from 'app/ducks/page-social-feed';
import AuthSlx            from 'app/selectors/auth';

class PageFeed extends React.PureComponent {

  constructor(props) {
    super(props);

  }

  render() {
    const {currentUser, listingIds, moreCount} = this.props;
    const company = currentUser.employment.company;
    return (
      <MainLayout className="page-feed">
        <Meta title="Feed | Millie" />
        <div className="widther">
          <br /><br /><br />
          <Feed initialIds={listingIds} type={SocialFeedTypes.COMPANY} id={company.id} title={company.name} initialMoreCount={moreCount} />
          <br /><br /><br /><br />
        </div>
      </MainLayout>
    );
  }

}

const stateToProps = (state) => ({
  currentUser: AuthSlx.currentUser(state),

  searchPending: Duck.Slx.searchPending(state),
  listingIds: Duck.Slx.listingIds(state),
  moreCount: Duck.Slx.moreCount(state),
});

const dispatchToProps = (dispatch) => ({
});

export default connect(stateToProps, dispatchToProps)(PageFeed);


