
import CadminCompaniesAx  from 'app/actions/company-admin/companies';
import CadminDashboardsAx from 'app/actions/company-admin/company-dashboards';
import {
  SocialFeedTypes,
}                         from 'app/constants';
import SocialDuck         from 'app/ducks/social';

const Types = {
  LOAD: 'CADMIN_PAGE_DB_VIEW_LOAD',
};

const Actions = {

  load: ({params: {companySlug, companyDashboardId}}) => (dispatch, getState) => {
    const promise = Promise.all([
      dispatch(CadminCompaniesAx.get(companySlug)),
      dispatch(CadminDashboardsAx.getData(companySlug, companyDashboardId)),
      dispatch(CadminDashboardsAx.get(companySlug, companyDashboardId)),
      dispatch(SocialDuck.Ax.listingsSearch({feedType: SocialFeedTypes.COMPANY, limit: 12})),
    ]);
    return dispatch({type: Types.LOAD, promise, companyDashboardId});
  },

};

export {
  Types,
};

export default Actions;
