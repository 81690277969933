
import VolEventsAx from 'app/actions/vol-events';
import {
  SocialFeedTypes
}                  from 'app/constants';
import SocialDuck  from 'app/ducks/social';

const feedType = SocialFeedTypes.EVENT;

const Types = {
  LOAD: 'PAGE_VOL_EVENT_LOAD',
};


const Actions = {

  load: (volEventId) => (dispatch, getState) => {
    const promise = Promise.all([
      dispatch(VolEventsAx.get(volEventId)),
      dispatch(SocialDuck.Ax.listingsSearch({feedType, feedId: volEventId, limit: 12})),
    ]);
    return dispatch({type: Types.LOAD, promise, volEventId});
  },
};

export {
  Types,
};

export default Actions;
