import _ from 'lodash';
import { createSelector } from 'reselect';

import EntitiesSlx from 'app/selectors/entities';

const selLoadPending      = state => state.pageBracket.loadPending;
const selBracketId        = state => state.pageBracket.bracketId;
const selSocialListingIds = state => state.pageBracket.socialListingIds;
const selSocialMoreCount  = state => state.pageBracket.socialMoreCount;

const selBracket = createSelector(
  [selBracketId, EntitiesSlx.brackets],
  (id, brackets) => {
    return brackets[id];
  }
);

const selVoteRecord = createSelector(
  [selBracketId, EntitiesSlx.bracketVoteRecords],
  (id, voteRecords) => {
    return voteRecords[id];
  }
);

const selNumberGameMap = createSelector(
  [selBracket, selVoteRecord, EntitiesSlx.bracketGames, EntitiesSlx.nonprofits],
  (bracket, voteRecord, bracketGames, nonprofits) => {
    if (!bracket?.bracketGameIds) return {};
    const games = bracket.bracketGameIds.map((id) => {
      const game = {...(bracketGames[id] || {})};
      game.aNonprofit = nonprofits[game.aNonprofitId];
      game.bNonprofit = nonprofits[game.bNonprofitId];
      game.votedNonprofitId = _.get(voteRecord, `${game.id}.nonprofitId`);
      game.isCurrent = game.roundNumber === bracket.currentRoundNumber;
      return game;
    });
    return games.reduce((map, game) => {
      return {...map, [game.number]: game};
    }, {});
  }
);

const selNumber = (state, number) => number;
const selGameByNumber = createSelector(
  [selNumber, selNumberGameMap],
  (number, numberGameMap) => {
    return numberGameMap[number];
  }
);

export default {
  loadPending: selLoadPending,
  bracket: selBracket,
  voteRecord: selVoteRecord,
  // numberGameMap: selNumberGameMap,
  gameByNumber: selGameByNumber,
  socialListingIds: selSocialListingIds,
  socialMoreCount: selSocialMoreCount,
};
