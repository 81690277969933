import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import BuilderAx          from 'app/actions/company-admin/builder-bracket';
import Checkbox           from 'app/components/common/checkbox';
import Icon               from 'app/components/common/icon';
import Link               from 'app/components/common/link';
import NonprofitInput     from 'app/components/common/nonprofit-input';
import StandardInput      from 'app/components/common/standard-input';
import StandardSelect     from 'app/components/common/standard-select';
import Builder            from 'app/components/company-admin/layout/builder';
import BuilderFooter      from 'app/components/company-admin/layout/builder-footer';
import BuilderSection     from 'app/components/company-admin/layout/builder-section';
import BuilderProgress    from 'app/components/company-admin/madness/builder-progress';
import ModalCollection    from 'app/components/company-admin/madness/modal-collection';
import RequireBackstage   from 'app/components/gating/require-backstage';
import config             from 'app/config';
import paths              from 'app/paths';
import CadminSlx          from 'app/selectors/company-admin/';
import BuilderSlx         from 'app/selectors/company-admin/builder-bracket';
import FfSlx              from 'app/selectors/feature-flags';

class BracketBuilder extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      selectedNonprofit: null,
      viewCollection: null,
    };

    this.refNonprofitInput = React.createRef();

    this.onClickSave = this.onClickSave.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeStartDateTime = this.onChangeStartDateTime.bind(this);
    this.onChangeSize = this.onChangeSize.bind(this);
    this.onChangeTimezone = this.onChangeTimezone.bind(this);
    this.onChangeRoundDuration = this.onChangeRoundDuration.bind(this);
    this.onChangeRoundDurationUnit = this.onChangeRoundDurationUnit.bind(this);
    this.onSelectNonprofit = this.onSelectNonprofit.bind(this);
    this.onClickAddNonprofit = this.onClickAddNonprofit.bind(this);
    this.onCloseCollection = this.onCloseCollection.bind(this);
    this.onColAddNonprofit = this.onColAddNonprofit.bind(this);
    this.onColAddNonprofits = this.onColAddNonprofits.bind(this);
    this.onChangeNotiEmailOn = this.onChangeNotiEmailOn.bind(this);
    this.onChangeNotiSlackOn = this.onChangeNotiSlackOn.bind(this);
    this.onChangeHasSocialFeed = this.onChangeHasSocialFeed.bind(this);
  }

  get hasIntl() {
    return !!this.props.company?.features?.international;
  }

  onChangeName(event) {
    this.props.setKeyVal('name', event.target.value);
  }
  onChangeStartDateTime(event) {
    this.props.setKeyVal('startDateTime', event.target.value);
  }
  onChangeSize(size) {
    this.props.setKeyVal('size', size);
  }
  onChangeTimezone(timezone) {
    this.props.setKeyVal('timezone', timezone);
  }
  onChangeRoundDuration(event) {
    this.props.setKeyVal('roundDuration', parseInt(event.target.value));
  }
  onChangeRoundDurationUnit(roundDurationUnit) {
    this.props.setKeyVal('roundDurationUnit', roundDurationUnit);
  }

  onChangeNotiEmailOn(event) {
    const notificationsEmailOn = !!event.target.checked;
    this.props.setKeyVal('notificationsEmailOn', notificationsEmailOn);
  }
  onChangeNotiSlackOn(event) {
    const notificationsSlackOn = !!event.target.checked;
    this.props.setKeyVal('notificationsSlackOn', notificationsSlackOn);
  }

  onChangeHasSocialFeed(event) {
    const hasSocialFeed = event.target.checked;
    this.props.setKeyVal('hasSocialFeed', hasSocialFeed);
  }

  onSelectNonprofit(selectedNonprofit) {
    // this.setState({selectedNonprofit});
    this.props.addNonprofit(selectedNonprofit);
    // clear the input
    const input = this.refNonprofitInput.current;
    input && input.clear();
  }
  onClickAddNonprofit() {
    this.props.addNonprofit(this.state.selectedNonprofit);
    this.setState({selectedNonprofit: null});
  }

  onClickCollection(collection, event) {
    event.preventDefault();
    this.setState({viewCollection: collection});
  }
  onCloseCollection() {
    this.setState({viewCollection: null});
  }
  onColAddNonprofit(nonprofit) {
    this.props.addNonprofit(nonprofit);
  }
  onColAddNonprofits(nonprofits) {
    this.props.addNonprofits(nonprofits);
  }

  onClickSave() {
    this.props.save(this.props.onSave);
  }

  /*
   *  Render Sections
   */

  renderSectionDetails() {
    const { size, notificationsEmailOn, notificationsSlackOn, bracketId } = this.props;
    const emailVal = _.isBoolean(notificationsEmailOn) ? notificationsEmailOn : true;
    const slackVal = _.isBoolean(notificationsSlackOn) ? notificationsSlackOn : true;
    return (<>
      <BuilderSection title="Notification Settings">
        <p className="ca-brkt-bldr-noti-desc">
          Notifications:
          <br />- Announcement upon bracket publish
          <br />- The start of each round
          <br />- Reminders near the end of the round (only for long duration rounds)
          <br />- Results upon completion of the bracket
        </p>
        <div className="ca-brkt-bldr-cb-row">
          <Checkbox id="cb-bracket-noti-email-on" onChange={this.onChangeNotiEmailOn} checked={emailVal} isToggle />
          <label htmlFor="cb-bracket-noti-email-on">Email</label>
        </div>
        <div className="ca-brkt-bldr-cb-row">
          <Checkbox id="cb-bracket-noti-slack-on" onChange={this.onChangeNotiSlackOn} checked={slackVal} isToggle />
          <label htmlFor="cb-bracket-noti-slack-on">Slack</label>
        </div>
      </BuilderSection>
      <RequireBackstage>
        <BuilderSection title="Top Secret Settings">
          {!bracketId && (<>
            <label>Size</label><br />
            <StandardSelect
              options={[{label: '4', value: 4}, {label: '8', value: 8}, {label: '16', value: 16}, {label: '32', value: 32}, {label: '64', value: 64}]}
              value={size}
              onSelect={this.onChangeSize}
            /><br /><br />  
          </>)}
        </BuilderSection>
      </RequireBackstage>
    </>);
  }

  renderProgress() {
    const { size, selectedNonprofitCount } = this.props;
    return <BuilderProgress target={size || 16} current={selectedNonprofitCount || 0} />
  }

  renderSectionNonprofit() {
    const { selectedNonprofit } = this.state;
    return (
      <div className="ca-brkt-bldr-section">
        <h2 className="ca-brkt-bldr-section-heading">Search Nonprofits</h2>
        <NonprofitInput onChange={this.onSelectNonprofit} label="Search nonprofit by EIN or name" ref={this.refNonprofitInput} intl={this.hasIntl} />
        {/* <p className="ca-brkt-bldr-section-help">Can't find an organization? Try searching with EIN.</p> */}
        <a href={paths.nonprofits()} target="_blank" className="ca-brkt-bldr-nonprofits-link">Browse all 1.7 million nonprofits <Icon.Caret direction="right" /></a>
      </div>
    );
  }

  renderSectionCols() {
    const { collections } = this.props;
    if (!collections?.length) return null;
    return (
      <div className="ca-brkt-bldr-section">
        <h2 className="ca-brkt-bldr-section-heading">Browse Bracket Collections</h2>
        <div className="ca-brkt-bldr-cols">
          {collections.map((col) => {
            const imgUrl = col.nonprofits.find(n => n.imgUrl)?.imgUrl;
            return (
              <div className="ca-brkt-bldr-cols-col" key={col.id} onClick={this.onClickCollection.bind(this, col)}>
                <img className="ca-brkt-bldr-cols-col-img" src={imgUrl} />
                <div className="ca-brkt-bldr-cols-col-details">
                  <div className="ca-brkt-bldr-cols-col-name">{col.title}</div>
                  <div className="ca-brkt-bldr-cols-col-count">{col.nonprofitIds.length} nonprofits</div>
                  <div className="ca-brkt-bldr-cols-col-actions">
                    <span className="ca-brkt-bldr-cols-col-actions-view">View <Icon.Caret direction="right" /></span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  renderSectionSocial() {
    const {ffSocial, hasSocialFeed} = this.props;
    if (!ffSocial) return null;
    return (
      <BuilderSection title="Activity Feed">
        <div className="cadmin-builder-checkbox">
          <Checkbox id="cb-camp-has-social" onChange={this.onChangeHasSocialFeed} checked={hasSocialFeed} isToggle offOk />
          <label htmlFor="cb-camp-has-social">Include Activity Feed</label>
        </div>
      </BuilderSection>
    );
  }

  renderChecklist() {
    const { actualValidations, selectedNonprofitCount, size } = this.props;
    const v = actualValidations || {};
    const nameComplete = !v.name;
    const dateComplete = !v.startDateTime;
    const brktComplete = selectedNonprofitCount >= size;
    const poolComplete = !v.poolKickstartAmountInCents && !v.requiredDonationAmountInCents && !v.matchPercent;
    const items = [
      {complete: nameComplete, label: 'Name bracket', onClick: null},
      {complete: dateComplete, label: 'Set timing', onClick: null},
      {complete: brktComplete, label: 'Select nonprofits', onClick: null},
      {complete: poolComplete, label: 'Donation pool', onClick: null},
    ];
    const todoCount = items.filter(i => !i.complete).length;
    return (
      <div className="cadmin-builder-bottom-checklist">
        <div className="cadmin-builder-bottom-checklist-heading">
          🚀 Checklist
          {!!todoCount && (
            <div className="cadmin-builder-bottom-checklist-heading-todo">{todoCount}</div>
          )}
        </div>
        <div className="cadmin-builder-bottom-checklist-items" style={{height: (items.length * 24)}}>
          {items.map((item) => {
            return (
              <div className={`cadmin-builder-bottom-checklist-item ${item.complete ? 'complete' : ''}`} key={item.label}>
                <Icon.CheckCircle1 className="cadmin-builder-bottom-checklist-item-check" />
                {item.onClick ? (
                  <a href="#" onClick={item.onClick} className="cadmin-builder-bottom-checklist-item-label">{item.label}</a>
                ) : (
                  <span className="cadmin-builder-bottom-checklist-item-label">{item.label}</span>
                )}
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  renderFooter() {
    const { hasTouched, hasSubmitted, savePending, isValid, bracketId, onSave } = this.props;
    const showValidation = hasSubmitted && !isValid;
    // const showValidation = true;
    const disabled = !hasTouched || savePending || showValidation;
    const btnText = onSave
      ? 'Save Bracket'
      : bracketId
        ? (savePending ? 'Saving...' : 'Save')
        : (savePending ? 'Creating...' : 'Create Draft');

    return (
      <BuilderFooter className="ca-brkt-bldr-bottom">
        {showValidation && (
          <div className="cadmin-builder-bottom-validations">
            <p>Oops! There are some required fields above that need your attention.</p>
          </div>
        )}
        <div className="cadmin-builder-bottom-actions">
          {this.renderChecklist()}
          <button className="btn green" disabled={disabled} onClick={this.onClickSave}>{btnText}</button>
        </div>
      </BuilderFooter>
    );
  }

  render() {
    const { viewCollection } = this.state;
    return (
      <Builder footer={this.renderFooter()} className="ca-brkt-bldr">
        {this.renderProgress()}
        {this.renderSectionNonprofit()}
        {this.renderSectionCols()}
        <br /><br /><br />
        {this.renderSectionSocial()}
        {this.renderSectionDetails()}
        {viewCollection && (
          <ModalCollection
            collection={viewCollection}
            onClose={this.onCloseCollection}
            onAddNonprofit={this.onColAddNonprofit}
            onAddNonprofits={this.onColAddNonprofits}
          />
        )}
      </Builder>
    );
  }

}

BracketBuilder.propTypes = {
  onSave: PropTypes.func,
};

const stateToProps = (state) => ({
  company: CadminSlx.company(state),
  ffSocial: FfSlx.social(state),
  // attrs
  name: BuilderSlx.name(state),
  startDateTime: BuilderSlx.startDateTime(state),
  size: BuilderSlx.size(state),
  timezone: BuilderSlx.timezone(state),
  roundDuration: BuilderSlx.roundDuration(state),
  roundDurationUnit: BuilderSlx.roundDurationUnit(state),
  notificationsEmailOn: BuilderSlx.notificationsEmailOn(state),
  notificationsSlackOn: BuilderSlx.notificationsSlackOn(state),
  hasSocialFeed: BuilderSlx.hasSocialFeed(state),
  // entities
  bracketId: BuilderSlx.bracketId(state),
  collections: BuilderSlx.collections(state),
  // builder state
  savePending: BuilderSlx.savePending(state),
  isValid: BuilderSlx.isValid(state),
  hasTouched: BuilderSlx.hasTouched(state),
  hasSubmitted: BuilderSlx.hasSubmitted(state),
  actualValidations: BuilderSlx.validations(state),
  validations: BuilderSlx.visibleValidations(state),
  //
  selectedNonprofitCount: BuilderSlx.selectedNonprofitCount(state),
});

const dispatchToProps = (dispatch) => ({
  addNonprofit: (nonprofit) => dispatch(BuilderAx.addNonprofit(nonprofit)),
  addNonprofits: (nonprofits) => dispatch(BuilderAx.addNonprofits(nonprofits)),
  setKeyVal: (key, val) => dispatch(BuilderAx.setKeyVal(key, val)),
  // setKeyVals: (keyVals) => dispatch(BuilderAx.setKeyVals(keyVals)),
  save: (saveFn) => dispatch(BuilderAx.save(saveFn)),
});

export default connect(stateToProps, dispatchToProps)(BracketBuilder);
