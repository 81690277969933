import _ from 'lodash';
import timm from 'timm';

import { Types } from 'app/actions/page-bracket';
import utils from 'app/reducers/utils';

const initialState = {
  loadPending: false,
  bracketId: null,
  socialListingIds: [],
  socialMoreCount: 0,
};

const reducer = utils.createReducer(initialState, {

  [`${Types.LOAD}_PENDING`]: (state, {bracketId}) => {
    return {...state,
      loadPending: true,
      bracketId,
      socialListingIds: [],
      socialMoreCount: 0,
    };
  },
  [`${Types.LOAD}_RESOLVED`]: (state, {bracketId, result}) => {
    if (bracketId !== state.bracketId) return state;
    return {...state,
      loadPending: false,
      socialListingIds: result[2].socialPostListings.map(l => l.id),
      socialMoreCount: result[2].moreCount,
    };
  },
  [`${Types.LOAD}_REJECTED`]: (state, {bracketId}) => {
    if (bracketId !== state.bracketId) return state;
    return {...state,
      loadPending: false,
    };
  },

});

export default reducer;
