import moment from 'moment';
import numeral from 'numeral';
import React from 'react';
import { connect } from 'react-redux';

import CadminDashboardsAx from 'app/actions/company-admin/company-dashboards';
import Meta               from 'app/components/common/meta';
import CadminLayout       from 'app/components/company-admin/layout/';
import Dashboard          from 'app/components/company-dashboards/dashboard';
import PageLoading        from 'app/components/layout/page-loading';
import CadminSlx          from 'app/selectors/company-admin/';
import PageSlx            from 'app/selectors/company-admin/page-dashboard-view';

class PageCadminDashboardEdit extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onChangeDateRange = this.onChangeDateRange.bind(this);
  }

  onChangeDateRange({startDate, endDate}) {
    const { dashboard, company, setDateRange } = this.props;
    setDateRange(company.id, dashboard.id, startDate, endDate);
  }

  render() {
    const { isLoading, company, dashboard, data, socialListingIds, socialMoreCount } = this.props;
    if (!company) return <PageLoading />;

    return (
      <CadminLayout className="page-cadmin-db-view" company={company} activeItem="dashboard-view">
        <Meta title="Company Dashboard | Millie" />
        <Dashboard {...{isLoading, company, dashboard, data}} onChangeDateRange={this.onChangeDateRange} socialListingIds={socialListingIds} socialMoreCount={socialMoreCount} />
      </CadminLayout>
    );
  }

}

const stateToProps = (state) => ({
  company: CadminSlx.company(state),

  dashboard: PageSlx.dashboard(state),
  data: PageSlx.dashboardData(state),
  isLoading: PageSlx.loadPending(state),
  socialListingIds: PageSlx.socialListingIds(state),
  socialMoreCount: PageSlx.socialMoreCount(state),
});

const dispatchToProps = (dispatch) => ({
  setDateRange: (companyId, dashboardId, startDate, endDate) => dispatch(CadminDashboardsAx.getData(companyId, dashboardId, {startDate, endDate})),
});

export default connect(stateToProps, dispatchToProps)(PageCadminDashboardEdit);
