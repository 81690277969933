import config from 'app/config';

const IMG_BASE_URL = `https://${process.env.IMG_RESIZE_DOMAIN}/cdn-cgi/image`;

const getAssetUrl = (filePath) => {
  if (!filePath) return null;
  const isFullUrl = filePath.startsWith('http') || filePath.startsWith('//');
  if (isFullUrl) return filePath;
  return encodeURI(`${config.s3BaseUrl}/${filePath}`);
};

// https://developers.cloudflare.com/images/transform-images/transform-via-url/
const getImgUrl = (filePath, {width=2000, height=2000, fit='scale-down'} = {}) => {
  const assetUrl = getAssetUrl(filePath);
  const options = `width=${width},height=${height},fit=${fit}`;
  const isLocalhost = assetUrl.startsWith('http://localhost:');
  if (isLocalhost) return assetUrl;
  return `${IMG_BASE_URL}/${options}/${assetUrl}`;
};

export default {
  assetUrl: getAssetUrl,
  imgUrl: getImgUrl,
};
