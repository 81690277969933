import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';

import BuilderAx          from 'app/actions/company-admin/builder-group';
import CampaignInput      from 'app/components/common/campaign-input';
import Checkbox           from 'app/components/common/checkbox';
import DatePicker         from 'app/components/common/date-picker';
import Icon               from 'app/components/common/icon';
import NonprofitInput     from 'app/components/common/nonprofit-input';
import SegmentedInput     from 'app/components/common/segmented-selector-input';
import StandardInput      from 'app/components/common/standard-input';
import UploadedImageInput from 'app/components/common/uploaded-image-input';
import VolEventInput      from 'app/components/common/vol-event-input';
import BuilderHighlight   from 'app/components/company-admin/groups/builder-highlight';
import BuilderResource    from 'app/components/company-admin/groups/builder-resource';
import BuilderStat        from 'app/components/company-admin/groups/builder-stat';
import GroupTypeSelect    from 'app/components/company-admin/groups/group-type-select';
import Builder            from 'app/components/company-admin/layout/builder';
import BuilderFooter      from 'app/components/company-admin/layout/builder-footer';
import BuilderIterable    from 'app/components/company-admin/layout/builder-iterable';
import BuilderSection     from 'app/components/company-admin/layout/builder-section';
import config             from 'app/config';
import CadminSlx          from 'app/selectors/company-admin/';
import BuilderSlx         from 'app/selectors/company-admin/builder-group';
import FfSlx              from 'app/selectors/feature-flags';

class GroupBuilder extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onClickSave = this.onClickSave.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.onSelectGroupType = this.onSelectGroupType.bind(this);
    this.onChangeBannerImg = this.onChangeBannerImg.bind(this);
    this.onChangeLogoImg = this.onChangeLogoImg.bind(this);
    this.onChangeDescription = this.onChangeDescription.bind(this);
    this.onClickAddHighlight = this.onClickAddHighlight.bind(this);
    this.onChangeHighlight = this.onChangeHighlight.bind(this);
    this.onChangeHasSocialFeed = this.onChangeHasSocialFeed.bind(this);
    this.onRemoveHighlight = this.onRemoveHighlight.bind(this);
    this.onClickAddStat = this.onClickAddStat.bind(this);
    this.onChangeStat = this.onChangeStat.bind(this);
    this.onRemoveStat = this.onRemoveStat.bind(this);
    this.onClickAddResource = this.onClickAddResource.bind(this);
    this.onChangeResource = this.onChangeResource.bind(this);
    this.onRemoveResource = this.onRemoveResource.bind(this);
    this.onRemoveNonprofit = this.onRemoveNonprofit.bind(this);
    this.onChangeNonprofit = this.onChangeNonprofit.bind(this);
    this.onClickAddNonprofit = this.onClickAddNonprofit.bind(this);
  }

  get hasIntl() {
    return !!this.props.company?.features?.international;
  }

  onChangeName(event) {
    const name = event.target.value;
    this.props.setKeyVal('name', name);
  }

  onSelectGroupType(groupTypeId) {
    this.props.setKeyVal('groupTypeId', groupTypeId);
  }

  onChangeBannerImg(bannerImgPath) {
    this.props.setKeyVal('bannerImgPath', bannerImgPath);
  }

  onChangeLogoImg(logoImgPath) {
    this.props.setKeyVal('logoImgPath', logoImgPath);
  }

  onChangeDescription(event) {
    const description = event.target.value;
    this.props.setKeyVal('description', description);
  }

  onClickAddHighlight() {
    this.props.addHighlight();
  }
  onChangeHighlight(index, highlight) {
    this.props.updateHighlight(index, highlight);
  }
  onRemoveHighlight(index) {
    this.props.removeHighlight(index);
  }

  onClickAddStat() {
    this.props.addStat();
  }
  onChangeStat(index, stat) {
    this.props.updateStat(index, stat);
  }
  onRemoveStat(index) {
    this.props.removeStat(index);
  }

  onClickAddResource() {
    this.props.addResource();
  }
  onChangeResource(index, resource) {
    this.props.updateResource(index, resource);
  }
  onRemoveResource(index) {
    this.props.removeResource(index);
  }

  onClickAddNonprofit() {
    const index = this.props.nonprofits.length;
    this.props.setNonprofit(null, index);
  }
  onRemoveNonprofit(index) {
    this.props.removeNonprofit(index);
  }
  onChangeNonprofit(index, nonprofit) {
    this.props.setNonprofit(nonprofit, index);
  }

  onChangeHasSocialFeed(event) {
    const hasSocialFeed = event.target.checked;
    this.props.setKeyVal('hasSocialFeed', hasSocialFeed);
  }

  onClickSave() {
    this.props.save();
  }

  /*
   *  Render Sections
   */

  renderSectionTitle() {
    const { name, validations } = this.props;
    return (
      <BuilderSection title="Group Name" isRequired>
        <StandardInput
          name="name"
          label="Name"
          value={name || ''}
          validations={validations}
          onChange={this.onChangeName}
        />
      </BuilderSection>
    );
  }

  renderSectionType() {
    const { groupTypeId, validations } = this.props;
    return (
      <BuilderSection title="Section" isRequired>
        <GroupTypeSelect
          selectedId={groupTypeId}
          onSelect={this.onSelectGroupType}
          validations={validations}
        />
      </BuilderSection>
    );
  }

  renderSectionImages() {
    const { bannerImgPath, logoImgPath, validations, company } = this.props;
    return (
      <BuilderSection title="Images" isRequired>
        <UploadedImageInput
          name="logoImgPath"
          label="Logo Image"
          pathValue={logoImgPath}
          maxImageWidth={2048}
          validations={validations}
          s3PathParts={[company.id, 'groups', 'logos']}
          onChange={this.onChangeLogoImg}
        />
        <UploadedImageInput
          name="bannerImgPath"
          label="Banner Image"
          pathValue={bannerImgPath}
          maxImageWidth={4096}
          validations={validations}
          s3PathParts={[company.id, 'groups', 'banners']}
          onChange={this.onChangeBannerImg}
        />
      </BuilderSection>
    );
  }

  renderSectionDescription() {
    const { description, validations } = this.props;
    return (
      <BuilderSection title="Short Description" isRequired>
        <StandardInput
          type="textarea"
          name="description"
          label="Description"
          value={description || ''}
          validations={validations}
          onChange={this.onChangeDescription}
        />
      </BuilderSection>
    );
  }

  renderSectionHighlights() {
    const { highlights, company, hasSubmitted } = this.props;
    const capReached = highlights.length >= 10;
    return (
      <BuilderSection title="Highlights">
        {highlights.map((highlight, index) => {
          return (
            <BuilderIterable index={index} key={index} onDelete={this.onRemoveHighlight}>
              <BuilderHighlight onChange={this.onChangeHighlight} index={index} highlight={highlight} s3PathParts={[company.id, 'groups']} hasSubmitted={hasSubmitted} />
            </BuilderIterable>
          );
        })}
        {!capReached && (
          <button className="btn small blue cadmin-builder-iterable-add" onClick={this.onClickAddHighlight}>+ Add Highlight</button>
        )}
      </BuilderSection>
    );
  }

  renderSectionStats() {
    const { stats, hasSubmitted } = this.props;
    const capReached = stats.length >= 10;
    return (
      <BuilderSection title="Statistics">
        {stats.map((stat, index) => {
          return (
            <BuilderIterable index={index} key={index} onDelete={this.onRemoveStat}>
              <BuilderStat onChange={this.onChangeStat} index={index} stat={stat} hasSubmitted={hasSubmitted} />
            </BuilderIterable>
          );
        })}
        {!capReached && (
          <button className="btn small blue cadmin-builder-iterable-add" onClick={this.onClickAddStat}>+ Add Stat</button>
        )}
      </BuilderSection>
    );
  }

  renderSectionResources() {
    const { resources, company, hasSubmitted } = this.props;
    return (
      <BuilderSection title="Resources">
        {resources.map((resource, index) => {
          return (
            <BuilderIterable index={index} key={index} onDelete={this.onRemoveResource}>
              <BuilderResource onChange={this.onChangeResource} index={index} resource={resource} hasSubmitted={hasSubmitted} />
            </BuilderIterable>
          );
        })}
        <button className="btn small blue cadmin-builder-iterable-add" onClick={this.onClickAddResource}>+ Add Resource</button>
      </BuilderSection>
    );
  }

  renderSectionEvents() {
    const {company} = this.props;
    return (
      <BuilderSection title="Events">
        <p className="italic">Events are added automatically when assigned to this group within the event builder.</p>
      </BuilderSection>
    );
  }

  renderSectionCampaigns() {
    const {company} = this.props;
    return (
      <BuilderSection title="Campaigns">
        <p className="italic">Campaigns are added automatically when assigned to this group within the campaign builder.</p>
      </BuilderSection>
    );
  }

  renderSectionNonprofit() {
    const { nonprofits } = this.props;
    return (
      <BuilderSection title="Nonprofits We Love">
        {nonprofits.map((nonprofit, index) => {
          return (
            <BuilderIterable index={index} key={index} onDelete={this.onRemoveNonprofit}>
              <NonprofitInput onChange={this.onChangeNonprofit.bind(this, index)} selectedNonprofit={nonprofit} intl={this.hasIntl} />
            </BuilderIterable>
          );
        })}
        <button className="btn small blue cadmin-builder-iterable-add" onClick={this.onClickAddNonprofit}>+ Add Nonprofit</button>
      </BuilderSection>
    );
  }

  renderSectionSocial() {
    const {ffSocial, hasSocialFeed} = this.props;
    if (!ffSocial) return null;
    return (
      <BuilderSection title="Activity Feed">
        <div className="cadmin-builder-checkbox">
          <Checkbox id="cb-camp-has-social" onChange={this.onChangeHasSocialFeed} checked={hasSocialFeed} isToggle offOk />
          <label htmlFor="cb-camp-has-social">Include Activity Feed</label>
        </div>
      </BuilderSection>
    );
  }

  renderFooter() {
    const { hasTouched, hasSubmitted, savePending, isValid, groupId } = this.props;
    const showValidation = hasSubmitted && !isValid;
    const disabled = !hasTouched || savePending || showValidation;
    const btnText = groupId
      ? (savePending ? 'Saving...' : 'Save')
      : (savePending ? 'Creating...' : 'Create');

    return (
      <BuilderFooter className="cadmin-groupb-bottom">
        {showValidation && (
          <div className="cadmin-builder-bottom-validations">
            <p>Oops! There are some required fields above that need your attention.</p>
          </div>
        )}
        <div className="cadmin-builder-bottom-actions">
          <button className="btn green" disabled={disabled} onClick={this.onClickSave}>{btnText}</button>
        </div>
      </BuilderFooter>
    );
  }

  render() {
    return (
      <Builder footer={this.renderFooter()} className="cadmin-groupb">
        {this.renderSectionTitle()}
        {this.renderSectionType()}
        {this.renderSectionImages()}
        {this.renderSectionDescription()}
        {this.renderSectionHighlights()}
        {this.renderSectionStats()}
        {this.renderSectionResources()}
        {this.renderSectionEvents()}
        {this.renderSectionCampaigns()}
        {this.renderSectionNonprofit()}
        {this.renderSectionSocial()}
      </Builder>
    );
  }

}

const stateToProps = (state) => ({
  company: CadminSlx.company(state),
  groupTypes: CadminSlx.groupTypes(state),
  ffSocial: FfSlx.social(state),
  // attrs
  name: BuilderSlx.name(state),
  groupTypeId: BuilderSlx.groupTypeId(state),
  description: BuilderSlx.description(state),
  bannerImgPath: BuilderSlx.bannerImgPath(state),
  logoImgPath: BuilderSlx.logoImgPath(state),
  highlights: BuilderSlx.highlights(state),
  stats: BuilderSlx.stats(state),
  resources: BuilderSlx.resources(state),
  hasSocialFeed: BuilderSlx.hasSocialFeed(state),
  // entities
  groupId: BuilderSlx.groupId(state),
  nonprofits: BuilderSlx.nonprofits(state),
  // builder state
  savePending: BuilderSlx.savePending(state),
  isValid: BuilderSlx.isValid(state),
  hasTouched: BuilderSlx.hasTouched(state),
  hasSubmitted: BuilderSlx.hasSubmitted(state),
  validations: BuilderSlx.visibleValidations(state),
});

const dispatchToProps = (dispatch) => ({
  addHighlight: () => dispatch(BuilderAx.addHighlight()),
  updateHighlight: (index, highlight) => dispatch(BuilderAx.updateHighlight(index, highlight)),
  removeHighlight: (index) => dispatch(BuilderAx.removeHighlight(index)),

  addStat: () => dispatch(BuilderAx.addStat()),
  updateStat: (index, stat) => dispatch(BuilderAx.updateStat(index, stat)),
  removeStat: (index) => dispatch(BuilderAx.removeStat(index)),

  addResource: () => dispatch(BuilderAx.addResource()),
  updateResource: (index, resource) => dispatch(BuilderAx.updateResource(index, resource)),
  removeResource: (index) => dispatch(BuilderAx.removeResource(index)),

  setNonprofit: (nonprofit, index) => dispatch(BuilderAx.setNonprofit(nonprofit, index)),
  removeNonprofit: (index) => dispatch(BuilderAx.removeNonprofit(index)),

  setKeyVal: (key, val) => dispatch(BuilderAx.setKeyVal(key, val)),
  setKeyVals: (keyVals) => dispatch(BuilderAx.setKeyVals(keyVals)),
  save: () => dispatch(BuilderAx.save()),
});

export default connect(stateToProps, dispatchToProps)(GroupBuilder);
