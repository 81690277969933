
import CompanyDashboardsAx from 'app/actions/company-dashboards';
import {
  SocialFeedTypes,
}                          from 'app/constants';
import SocialDuck          from 'app/ducks/social';

const Types = {
  LOAD: 'PAGE_COMPANY_DASHBOARD_LOAD',
};

const Actions = {

  load: (companyDashboardId, {startDate, endDate} = {}) => (dispatch, getState) => {
    const promise = Promise.all([
      dispatch(CompanyDashboardsAx.getData(companyDashboardId, {startDate, endDate})),
      dispatch(SocialDuck.Ax.listingsSearch({feedType: SocialFeedTypes.COMPANY, limit: 12})),
    ]);
    return dispatch({ type: Types.LOAD, promise, companyDashboardId });
  },

};

export {
  Types,
};

export default Actions;
