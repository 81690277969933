
import BracketsAx from 'app/actions/brackets';
import {
  SocialFeedTypes,
}                 from 'app/constants';
import SocialDuck from 'app/ducks/social';

const Types = {
  LOAD: 'PAGE_BRACKET_LOAD',
};

const Actions = {

  load: (bracketId) => (dispatch, getState) => {
    const promise = Promise.all([
      dispatch(BracketsAx.get(bracketId)),
      dispatch(BracketsAx.getVoteRecord(bracketId)),
      dispatch(SocialDuck.Ax.listingsSearch({feedType: SocialFeedTypes.BRACKET, feedId: bracketId, limit: 20})),
    ]);
    return dispatch({type: Types.LOAD, promise, bracketId});
  },
};

export {
  Types,
};

export default Actions;
