import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import BuilderAx   from 'app/actions/company-admin/builder-bracket';
import Icon        from 'app/components/common/icon';
import ModalPool   from 'app/components/company-admin/madness/modal-pool';
import ModalTiming from 'app/components/company-admin/madness/modal-timing';
import Bracket     from 'app/components/madness/bracket';
import Draft       from 'app/components/madness/draft';
import Divvy       from 'app/components/madness/divvy';
import Feed        from 'app/components/social/feed';
import {
  SocialFeedTypes,
}                  from 'app/constants';
import helpers     from 'app/helpers/brackets';
import format      from 'app/helpers/format';
import AuthSlx     from 'app/selectors/auth';
import BuilderSlx  from 'app/selectors/company-admin/builder-bracket';

class BracketEditor extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      editingName: false,
      editingPool: false,
      editingTiming: false,
    };

    this.refNameInput = React.createRef();

    this.onDropNonprofit = this.onDropNonprofit.bind(this);
    this.onDeleteNonprofit = this.onDeleteNonprofit.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.onBlurName = this.onBlurName.bind(this);
    this.onClickName = this.onClickName.bind(this);
    this.onClickPool = this.onClickPool.bind(this);
    this.onClosePoolModal = this.onClosePoolModal.bind(this);
    this.onClickTiming = this.onClickTiming.bind(this);
    this.onCloseTimingModal = this.onCloseTimingModal.bind(this);
  }

  onDropNonprofit({nonprofitId, index}) {
    this.props.setNonprofit({nonprofitId, index});
  }
  onDeleteNonprofit({nonprofitId}) {
    this.props.removeNonprofit({nonprofitId});
  }

  onChangeName(event) {
    this.props.setKeyVal('name', event.target.value);
  }
  onClickName(event) {
    this.setState({editingName: true});
    setTimeout(() => {
      this.refNameInput.current && this.refNameInput.current.focus();
    }, 0);
  }
  onBlurName(event) {
    this.setState({editingName: false});
  }

  onClickPool() {
    this.setState({editingPool: true});
  }
  onClosePoolModal() {
    this.setState({editingPool: false});
  }

  onClickTiming() {
    this.setState({editingTiming: true});
  }
  onCloseTimingModal() {
    this.setState({editingTiming: false});
  }

  render() {
    const { editingName, editingPool, editingTiming } = this.state;
    const { bracket, name, startDateStr, startTimeStr, timezone, poolKickstartAmountInCents, validations, currentUser, hasSocialFeed } = this.props;
    const poolAmountFmt = helpers.poolAmountFmt(bracket);
    const poolSet = _.isFinite(poolKickstartAmountInCents);
    const timingSet = !!startDateStr;
    const nameSet = !!(name || '').trim();
    const nameRequiredClass = nameSet ? '' : 'required';
    const timingBtnLabel = timingSet
      ? (moment.utc(`${startDateStr}T${startTimeStr}`).format('MMM D, YYYY @ h:mma') + ` ${format.tzAbbr(timezone)}`)
      : 'Set Timing';

    return (
      <div className="ca-brkt-edit">
        <Draft bracket={bracket} currentUser={currentUser} isEditing />
        <div className="ca-brkt-edit-row1">
          {editingName ? (
            <input type="text" className="ca-brkt-edit-row1-name-input" value={name || ''} ref={this.refNameInput} onChange={this.onChangeName} onBlur={this.onBlurName} />
          ) : (
            <h1 className={`ca-brkt-edit-row1-name-h1 ${nameRequiredClass}`} onClick={this.onClickName}>{(name || '').trim() || 'Bracket Name'}</h1>
          )}
          <div className="ca-brkt-edit-row1-controls">
            <button className={`btn secondary icon ${timingSet ? '' : 'timing-required'}`} onClick={this.onClickTiming}><Icon.Calendar /> {timingBtnLabel}</button>
          </div>
        </div>
        <div className="ca-brkt-edit-row2">
          <div />
          <div className="ca-brkt-edit-row2-pool" onClick={this.onClickPool}>
            Pool of donations at {poolSet ? <b>&nbsp;{poolAmountFmt}</b> : <span className="ca-brkt-edit-row2-pool-skel" />}
          </div>
        </div>
        <Bracket bracket={bracket} onDropNonprofit={this.onDropNonprofit} onDeleteNonprofit={this.onDeleteNonprofit} />
        <Divvy bracket={bracket} />
        {hasSocialFeed && (
          <div className="gm-profile-social">
            <h2 className="gm-profile-social-heading">Activity Feed</h2>
            <Feed
              type={SocialFeedTypes.BRACKET}
              id={bracket.id}
              initialIds={[]}
              initialMoreCount={0}
              title={bracket.name || ''}
              className="gm-profile-social-feed"
              editMode={true}
            />
          </div>
        )}
        {editingPool && (
          <ModalPool onClose={this.onClosePoolModal} />
        )}
        {editingTiming && (
          <ModalTiming onClose={this.onCloseTimingModal} />
        )}
      </div>
    );
  }

}

BracketEditor.propTypes = {
  bracket: PropTypes.object.isRequired,
};

BracketEditor.defaultProps = {
};

const stateToProps = (state) => ({
  currentUser: AuthSlx.currentUser(state),

  name: BuilderSlx.name(state),
  timezone: BuilderSlx.timezone(state),
  startDateStr: BuilderSlx.startDateStr(state),
  startTimeStr: BuilderSlx.startTimeStr(state),
  poolKickstartAmountInCents: BuilderSlx.poolKickstartAmountInCents(state),
  requiredDonationAmountInCents: BuilderSlx.requiredDonationAmountInCents(state),
  validations: BuilderSlx.visibleValidations(state),
  hasSocialFeed: BuilderSlx.hasSocialFeed(state),
});

const dispatchToProps = (dispatch) => ({
  setKeyVal: (key, val) => dispatch(BuilderAx.setKeyVal(key, val)),
  setKeyVals: (keyVals) => dispatch(BuilderAx.setKeyVals(keyVals)),
  setNonprofit: ({nonprofitId, index}) => dispatch(BuilderAx.setNonprofit({nonprofitId, index})),
  removeNonprofit: ({nonprofitId}) => dispatch(BuilderAx.removeNonprofit({nonprofitId})),
});

export default connect(stateToProps, dispatchToProps)(BracketEditor);
