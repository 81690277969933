import moment from 'moment';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import ModalDriveTrack          from 'app/components/campaigns/modal-drive-track';
import ActionTiles              from 'app/components/common/action-tiles';
import AmountSelector           from 'app/components/common/amount-selector';
import FakeLines                from 'app/components/common/fake-lines';
import Icon                     from 'app/components/common/icon';
import ProgressBar              from 'app/components/common/progress-bar';
import RecentDonors             from 'app/components/common/recent-donors';
import ProfileMain              from 'app/components/event-profiles/main';
import ProfileTop               from 'app/components/event-profiles/top';
import NonprofitCard            from 'app/components/nonprofits/nonprofit-card';
import Feed                     from 'app/components/social/feed';
import VolEventCard             from 'app/components/volunteer/vol-event-card';
import {
  CampaignStatuses,
  CAMP_DEMO_A,
  CAMP_DEMO_B,
  DonatableTypes,
  DriveGoalTypes,
  VolEventTypes,
  SocialFeedTypes,
}                               from 'app/constants';
import ModalConfirmDonationDuck from 'app/ducks/modal-confirm-donation';
import ModalDriveTrackDuck      from 'app/ducks/modal-drive-track';
import campaignsHelper          from 'app/helpers/campaigns';
import format                   from 'app/helpers/format';
import utils                    from 'app/helpers/utils';
import AuthSlx                  from 'app/selectors/auth';
import FfSlx                    from 'app/selectors/feature-flags';

// TODO: remove after demo session
import DemoRecentDonors from './demo-recent-donors';
import DemoRecentBackersGrid from './demo-recent-backers-grid';
const DEMO_CAMPAIGN_IDS = [...CAMP_DEMO_A, ...CAMP_DEMO_B];

const dateFormat = 'MMMM Do';
const dateFormatWithDay = 'dddd, MMMM Do';

const statusToLabel = {
  [CampaignStatuses.UPCOMING]: 'COMING SOON',
  [CampaignStatuses.ENDED]: 'Ended',
};

const statusToClassName = {
  [CampaignStatuses.UPCOMING]: 'coming-soon-label',
  [CampaignStatuses.ACTIVE]: 'active-label',
  [CampaignStatuses.ENDED]: 'ended-label',
};

const NPCardPlaceholderCount = 3;

class CampaignProfile extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      selectedFundAmount: null,
    };

    this.getEmoji = utils.makeDanceEmojiCycler();

    this.onChangeFundAmount = this.onChangeFundAmount.bind(this);
    this.onClickFundDonate = this.onClickFundDonate.bind(this);
    this.onClickDriveTrack = this.onClickDriveTrack.bind(this);
  }

  get matchRatio() {
    const {company, campaign} = this.props;
    const matchPercent = [campaign?.matchPercent, company?.currentMatchPercent, 0].find(_.isFinite);
    return format.matchRatio(matchPercent, {showZero: true});
  }

  get hasMatch() {
    const {budgetAllocatedAmount} = this.props.campaign;
    // budgetAllocatedAmount=null is ok and means the annual match budget is used
    return (this.matchRatio !== '0:1') && (budgetAllocatedAmount !== 0);
  }

  get isDemo() {
    const {campaign} = this.props;
    return DEMO_CAMPAIGN_IDS.includes(campaign.id);
  }

  onClickDriveTrack() {
    this.props.openDriveTrackModal(this.props.campaign.id);
  }

  onChangeFundAmount(amount) {
    this.setState({selectedFundAmount: amount});
  }

  onClickFundDonate() {
    return null; // don't actually allow this yet
    // TODO: update this to be currency-aware
    const donatableType = DonatableTypes.FUND;
    const { selectedFundAmount } = this.state;
    const { confirmDonation, campaign } = this.props;
    const { type: freqType, amountInCents } = selectedFundAmount;
    confirmDonation({freqType, amountInCents, donatableId: campaign.fundId, donatableType});
  }

  renderDescription() {
    const { campaign: { description, links }, editMode } = this.props;
    const showDesc = !!(editMode || description);
    return showDesc && (
      <div className="campaign-headline-details-blurb">
        {description
          ? (description || '').split('\n').map((p, i) => <p key={i}>{p}</p>)
          : <FakeLines count={2} lineHeight={15} />
        }
        <ProfileTop.Links links={links} />
      </div>
    );
  }

  renderStartEndDates() {
    const { campaign, editMode } = this.props;
    const { startDate, endDate, endsAt, isOngoing } = campaign;

    const campaignStatus = campaignsHelper.getStatus(campaign);
    const statusLabel = statusToLabel[campaignStatus];
    const statusClassName = statusToClassName[campaignStatus];
    const startStr = moment(startDate).format(dateFormat);
    const endStr = moment(endDate).format(dateFormat);
    const dateRangeTxt = `${startStr} - ${endStr}`;
    let activeTxt = `${dateRangeTxt}`;
    if (endsAt) {
      const remainingDays = moment(endsAt).fromNow();
      activeTxt += ` | ends ${remainingDays}`;
    }
    const showPlaceholder = !!((!startDate || !endDate) && !isOngoing && editMode);
    
    if (showPlaceholder) {
      return <FakeLines count={1} lineHeight={14} />;
    }
    if (!startDate || !endDate) return null;

    return (
      <p className="campaign-headline-details-dates">
        {campaignStatus === CampaignStatuses.ACTIVE ? (
          activeTxt
        ) : (
          <>
            <span className={statusClassName}>
              <strong>{statusLabel}</strong>
            </span>
            <span className="date-range-label">{dateRangeTxt}</span>
          </>
        )}
      </p>
    );
  }

  renderMatchDetails() {
    const {campaign, company, editMode} = this.props;
    const {goalAmountInCents, matchPercent, budgetAllocatedAmount} = campaign;
    const hasBudget = budgetAllocatedAmount > 0;
    const hasGoal = goalAmountInCents > 0;
    const goalCopy = hasGoal
      ? `${this.hasMatch ? 'Total goal' : 'Goal'} is ${numeral(goalAmountInCents / 100).format('$0,0')}`
      : null;
    const budgetCopy = hasBudget
      ? `${company.name} will contribute up to ${numeral(budgetAllocatedAmount / 100).format('$0,0')} in donation matches for this campaign.`
      : 'Annual match program';
    const matchCopy = this.hasMatch
      ? `${this.matchRatio} Match | ${budgetCopy}`
      : null;
    const showPlaceholder = !!(editMode && !goalCopy && !matchCopy);

    return `${matchCopy || goalCopy || ''}`.trim();

    return (
      <div className="campaign-headline-details-match">
        <div className="goal-dollars">
          {showPlaceholder && (
            <div className="goal-dollars-placeholder">
              Goal is <FakeLines count={1} lineHeight={14} />
            </div>
          )}
          {`${matchCopy || goalCopy || ''}`.trim()}
        </div>
      </div>
    );
  }

  renderDonateSocial() {
    const { campaign } = this.props;
    if (!campaign) return null;
    const { donorCount, recentDonors } = campaign;
    if (!donorCount || !recentDonors) return null;
    return (
      <RecentDonors
        className="campaign-recent-donors"
        recentDonors={recentDonors}
        donorCount={donorCount}
      />
    );
  }

  renderDonateProgressBar() {
    const { campaign: { goalAmountInCents, totalRaisedAmount }, editMode } = this.props;
    const showBar = !!(goalAmountInCents || editMode);
    if (editMode && !goalAmountInCents) {
      return (
        <div className="campaign-progress-bar-placeholder">
          <div className="amounts">
            <FakeLines count={1} lineHeight={31} />
            <div className="goal">
              <FakeLines count={1} lineHeight={10} />
            </div>
          </div>
          <div className="bar">
            <FakeLines count={1} lineHeight={7} />
          </div>
        </div>
      );
    }

    return (
      <ProgressBar
        thinBar
        titleSize="xl"
        title={`${numeral((totalRaisedAmount / 100)).format('$0,0')} Raised`}
        targetLabel={`${numeral((goalAmountInCents / 100)).format('$0,0')}`}
        targetNumber={goalAmountInCents}
        actualNumber={totalRaisedAmount}
        className="campaign-progress-bar"
        color="purple"
        emoji={this.getEmoji()}
      />
    );
  }

  renderCampaignStatusCard() {
    const { campaign, editMode } = this.props;
    const { startDate, endDate } = campaign;
    const showCardPlaceholder = ((!startDate || !endDate) && editMode);
    if (showCardPlaceholder) return this.renderCampaignStatusCardPlaceholder();
    const campaignStatus = campaignsHelper.getStatus(campaign);    
    if (campaignStatus === CampaignStatuses.UPCOMING) return this.renderComingSoonCard();
    if (campaignStatus === CampaignStatuses.ENDED) return this.renderHasEndedCard();
    return null;
  }

  renderDriveTrackers() {
    const {campaign, editMode} = this.props;
    const {driveGoalType, driveGoalValue, driveTotalQuantity, driveTotalValue, driveGoods} = campaign;
    // build trackers
    const trackers = [];
    const showOverallTracker = !!(driveGoalType && driveGoalValue);
    // overall tracker
    if (showOverallTracker) {
      const isMonetary = driveGoalType === DriveGoalTypes.MONETARY;
      trackers.push({
        targetNumber: driveGoalValue,
        actualNumber: isMonetary ? driveTotalValue : driveTotalQuantity,
        title: (isMonetary
          ? `${numeral(Math.ceil((driveTotalValue || 0) / 100)).format('$0,0')} Total`
          : `${numeral(driveTotalQuantity).format('0,0')} Items`
        ),
        targetLabel: isMonetary ? numeral(Math.ceil(driveGoalValue / 100)).format('$0,0') : numeral(driveGoalValue).format('0,0'),
        emoji: this.getEmoji(),
        color: 'green',
        titleSize: 'xl',
      });
    }
    // individual goods trackers
    _.sortBy((driveGoods || []), (good) => {
      const hasGoal = !(good.goalType && good.goalValue);
      return [hasGoal, good.name];
    }).forEach((good) => {
      const isPrimary = !showOverallTracker && (driveGoods.length === 1);
      const hasGoal = !!(good.goalType && good.goalValue);
      const isMonetary = good.goalType === DriveGoalTypes.MONETARY;
      const name = good.name || (editMode ? 'Items' : '');
      trackers.push({
        targetNumber: good.goalValue,
        actualNumber: isMonetary ? good.totalValue : good.totalQuantity,
        title: `${numeral(good.totalQuantity).format('0,0')} ${name}`,
        targetLabel: isMonetary ? numeral(Math.ceil(good.goalValue / 100)).format('$0,0') : numeral(good.goalValue || 0).format('0,0'),
        emoji: this.getEmoji(),
        color: isPrimary ? 'green' : 'black',
        titleSize: isPrimary ? 'xl' : 'large',
      });
    });

    if (!trackers.length && editMode) {
      return (
        <div className="campaign-progress-bar-placeholder">
          <div className="amounts">
            <FakeLines count={1} lineHeight={31} />
            <div className="goal">
              <FakeLines count={1} lineHeight={10} />
            </div>
          </div>
          <div className="bar">
            <FakeLines count={1} lineHeight={7} />
          </div>
        </div>
      );
    }

    return (<>
      {trackers.map((tracker, i) => {
        return <ProgressBar thinBar key={i} {...tracker} />;
      })}
    </>);
  }

  renderActivityDrive() {
    const {editMode, campaign} = this.props;
    if (!campaign?.hasDrive) return null;
    const showTrack = campaign.driveAllowEmpTracking;
    return (
      <div className="cam-pro-act green">
        <h2 className="cam-pro-act-title"><Icon.ProductsGiftGive /> Drives</h2>
        <div className="cam-pro-act-box">
          {showTrack && (
            <div className="cam-pro-act-box-aside">
              <div className="cam-pro-act-box-cta">
                <label>Track your items</label>
                <button className="btn icon special green" disabled={editMode} onClick={this.onClickDriveTrack}><Icon.AthleticsJumpingPerson /> Track Items</button>
              </div>
            </div>
          )}
          <div className="cam-pro-act-box-main">
            {this.renderDriveTrackers()}
          </div>
        </div>
      </div>
    );
  }

  renderActivityDonate() {
    const {editMode, campaign} = this.props;
    if (!campaign?.hasGive) return null;
    return (
      <div className="cam-pro-act purple">
        <h2 className="cam-pro-act-title"><Icon.PaginateFilterHeart /> Donate</h2>
        <div className="cam-pro-act-details">{this.renderMatchDetails()}</div>
        <div className="cam-pro-act-box">
          <div className="cam-pro-act-box-main">
            {this.renderDonateProgressBar()}
            {this.isDemo ? <DemoRecentDonors cid={campaign.id} /> : this.renderDonateSocial()}
          </div>
        </div>
      </div>
    );
  }

  renderCampaignStatusCardPlaceholder() {
    return (
      <div className="campaign-status-card-placeholder">
        <FakeLines count={1} lineHeight={24} />
        <FakeLines count={1} lineHeight={14} />
      </div>
    );
  }

  renderComingSoonCard() {
    const { campaign: { startDate, startsAt } } = this.props;
    const startStr = moment(startDate).format(dateFormatWithDay);
    const daysToStart = startsAt ? moment(startsAt).fromNow() : 'soon';
    const isComingTxt = `This campaign kicks off ${daysToStart}`;
    return (
      <div className="campaign-status-card">
        <div className="campaign-status-card-title coming-soon">
          <Icon.StartupLaunch1 /> {isComingTxt}
        </div>
        <div className="campaign-status-card-details">
          Campaign starts: {startStr}
        </div>
      </div>
    );
  }

  renderHasEndedCard() {
    const { campaign: { endDate, endsAt } } = this.props;
    const endStr = moment(endDate).format(dateFormatWithDay);
    const daysSinceEnded = endsAt ? moment(endsAt).fromNow() : '';
    const hasEndedTxt = `This campaign ended ${daysSinceEnded}`;
    return (
      <div className="campaign-status-card">
        <div className="campaign-status-card-title">
          <Icon.MoodPeace /> {hasEndedTxt}
        </div>
        <div className="campaign-status-card-details">
          Campaign ended: {endStr}
        </div>
      </div>
    );
  }

  renderActivityVolunteer() {
    const {campaign, editMode} = this.props;
    if (!campaign?.hasVol) return null;
    const volEvents = _.get(campaign, 'volEvents', []).filter(ve => ve);
    if (!volEvents.length && !editMode) return null;
    const hasGroupEvents = !!(volEvents || []).find(ve => ve.type === VolEventTypes.EVENT);

    return (<>
      <div className="cam-pro-act orange">
        <h2 className="cam-pro-act-title"><Icon.HandExpand /> {hasGroupEvents ? 'Volunteering & Events' : 'Volunteer'}</h2>
      </div>
      <div className="campaign-section cam-pro-events">
        <div className="cam-pro-events-events">
          {volEvents.map((ve) => (
            <div key={ve.id} className="cam-pro-events-event">
              <VolEventCard id={ve.id} />
            </div>
          ))}
        </div>
      </div>
    </>);
  }

  renderFundDonate() {
    const { campaign, editMode, currentUser } = this.props;
    const balance = currentUser ? format.usd(currentUser.balanceAmount) : '$0';
    const { selectedFundAmount } = this.state;
    if (!campaign.isFund) return null;
    return (
      <div className="campaign-fund">
        <h3 className="campaign-section-title">Donate to the Fund</h3>
        <div className="campaign-fund-row">
          <div className="campaign-fund-donate">
            <AmountSelector.UserCurrency multipliers={[5, 10, 20]} onChange={this.onChangeFundAmount} selectedAmount={selectedFundAmount} inline type={AmountSelector.TYPE_ONE_OFF} />
            <button className="btn special sunrise campaign-fund-donate-btn" disabled={!selectedFundAmount} onClick={this.onClickFundDonate}>Donate</button>
          </div>
          <div className="campaign-fund-desc">
            <p className="campaign-fund-desc-exp">Donations to this fund will get equally dispersed amongst the fund's nonprofits.</p>
            <p className="campaign-fund-desc-type">Donation will be deducted from your Giving Wallet</p>
            <p className="campaign-fund-desc-bal">Current Wallet Balance: <strong>{balance}</strong></p>
          </div>
        </div>
      </div>
    );
  }

  renderNonprofits() {
    const { campaign, editMode } = this.props;
    if (!campaign?.hasGive) return null;
    const nonprofitIds = _.get(campaign, 'nonprofitIds', []);
    const showCards = !!(nonprofitIds.length || editMode);
    const editCardProps = editMode ? {target: '_blank', donateDisabled: true} : {};
    
    // add nonprofit card placeholders
    while (editMode && nonprofitIds.length < NPCardPlaceholderCount) {
      nonprofitIds.push('');
    }

    return showCards && (
      <div className="campaign-section nonprofits">
        <div className="campaign-cards">
          {nonprofitIds.map((id, idx) => {
            return <NonprofitCard id={id} key={id || idx} withDonationBox={!campaign.isFund} campaignId={campaign.id} {...editCardProps} />;
          })}
        </div>
        {this.renderFundDonate()}
      </div>
    );
  }

  renderRecentBackersGrid() {
    const { actionIds } = this.props;
    if (!actionIds || _.isEmpty(actionIds)) return null;
    return (
      <div className="campaign-section backers">
        <h3 className="campaign-section-title">
          Recent Backers
        </h3>
        <ActionTiles ids={actionIds} className="page-campaign-actions" />
      </div>
    );
  }

  renderSocial() {
    const {socialListingIds: listingIds, socialMoreCount: moreCount, campaign, editMode, ffSocial} = this.props;
    if (!ffSocial) return null;
    const id = editMode ? 'foo' : campaign.id;
    if (!campaign?.hasSocialFeed) return null;

    return (
      <div className="cam-pro-act pink">
        <h2 className="cam-pro-act-title"><Icon.LayoutDashboard /> Activity Feed</h2>
        <Feed initialIds={listingIds || []} type={SocialFeedTypes.CAMPAIGN} id={id} title={campaign?.name || ''} initialMoreCount={moreCount} className="cam-pro-social" editMode={editMode} />
      </div>
    );
  }

  render() {
    const { campaign, company, editMode, showDriveTrackModal } = this.props;
    const top = (
      <ProfileTop groups={campaign.groups} title={campaign.name} imgUrl={campaign.imgPath} company={company} editMode={editMode}>
        {this.renderDescription()}
        {this.renderStartEndDates()}
      </ProfileTop>
    );

    return (
      <ProfileMain className="cam-pro" top={top} editMode={editMode}>
        {this.renderCampaignStatusCard()}
        {this.renderActivityDrive()}
        {this.renderActivityDonate()}
        {this.renderNonprofits()}
        {this.renderActivityVolunteer()}
        {this.renderSocial()}
        {this.isDemo ? <DemoRecentBackersGrid cid={campaign.id} /> : this.renderRecentBackersGrid()}
        {showDriveTrackModal && (
          <ModalDriveTrack />
        )}
      </ProfileMain>
    );
  }
}

CampaignProfile.propTypes = {
  actionIds: PropTypes.arrayOf(PropTypes.string),
  campaign: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  editMode: PropTypes.bool,
  socialListingIds: PropTypes.arrayOf(PropTypes.string),
  socialMoreCount: PropTypes.number,
};

CampaignProfile.defaultProps = {
  actionIds: null,
  editMode: false,
  socialListingIds: null,
  socialMoreCount: null,
};

const stateToProps = (state, ownProps) => ({
  ffSocial: FfSlx.social(state),
  currentUser: AuthSlx.currentUser(state),
  showDriveTrackModal: ModalDriveTrackDuck.Slx.campaignId(state) === ownProps.campaign?.id,
});

const dispatchToProps = (dispatch) => ({
  confirmDonation: (donation) => dispatch(ModalConfirmDonationDuck.Ax.show(donation)),
  openDriveTrackModal: (campaignId) => dispatch(ModalDriveTrackDuck.Ax.open(campaignId)),
});

export default connect(stateToProps, dispatchToProps)(CampaignProfile);
