import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';

import ClientTimestamp from 'app/components/common/client-timestamp';
import FakeLines       from 'app/components/common/fake-lines';
import Icon            from 'app/components/common/icon';
import Link            from 'app/components/common/link';
import Text            from 'app/components/common/text';
import Prompt          from 'app/components/social/post-card-prompt';
import ReactionSet     from 'app/components/social/reaction-set';
import UrlPreview      from 'app/components/social/url-preview';
import {
  USER_DEFAULT_AVATAR_URL,
  SocialReactionTargets as ReactionTargets,
}                      from 'app/constants';
import SocialDuck      from 'app/ducks/social';
import ViewDuck        from 'app/ducks/social-post-view';
import cdn             from 'app/helpers/cdn';
import helpers         from 'app/helpers/social';
import paths           from 'app/paths';

const noop = () => {};

class PostCard extends React.PureComponent {

  constructor(props) {
    super(props);

    this.refCard = React.createRef();
    this.reportedHeight = null;

    this.onLoadImg = this.onLoadImg.bind(this);
    this.onErrorImg = this.onErrorImg.bind(this);
    this.onClickCard = this.onClickCard.bind(this);
    this.onClickInternalLink = this.onClickInternalLink.bind(this);
  }

  componentDidMount() {
    this.calcHeight();
  }

  componentDidUpdate(prevProps) {
    this.calcHeight();
  }

  get largeText() {
    return helpers.useLargeText(this.post);
  }

  get isPrompt() {
    return this.props.id === 'prompt';
  }

  get skeleton() {
    const [type, variant] = (this.props.id || '').split('-');
    if (type === 'variant') return parseInt(variant);
    return null;
  }

  get isActual() {
    return !this.isPrompt && !this.skeleton;
  }

  get post() {
    return this.props.listing?.post || null;
  }
  get employee() {
    return this.post?.employee || null;
  }
  get user() {
    return this.employee?.user || null;
  }

  get isDeleted() {
    return !!this.post?._deleted;
  }

  calcHeight() {
    if (!this.props.onCalcHeight) return;
    const height = this.isDeleted ? -24 : this.refCard.current.clientHeight;
    if (height !== this.reportedHeight) {
      this.reportedHeight = height;
      this.props.onCalcHeight(this.props.id, height);
    }
  }

  onClickCard(event) {
    const clickedAnchor = event.target.tagName.toUpperCase() === 'A';
    if (clickedAnchor) return;
    this.props.openModal(this.post.id, this.props.listing.id);
  }

  onClickInternalLink(event) {
    event.stopPropagation();
  }

  onLoadImg() {
    this.calcHeight();
  }

  onErrorImg() {
    this.calcHeight();
  }

  renderFoot() {
    const {post} = this;
    const {listing} = this.props;
    const {employee, user, commentCount=0, createdTs} = post;
    const avatarUrl = user.avatarUrl
      ? cdn.imgUrl(user.avatarUrl, {width: 60})
      : USER_DEFAULT_AVATAR_URL;
    return (
      <div className="spost-card-foot">
        <img className="spost-card-foot-img" src={avatarUrl} />
        <div className="spost-card-foot-det">
          <div className="spost-card-foot-det-name">
            <Link onClick={this.onClickInternalLink} href={paths.user(user.id)} className="pink-hover">{`${employee.firstName} ${employee.lastName}`}</Link>
          </div>
          <Link href={paths.socialPost(post.id, {listingId: listing.id})} className="spost-card-foot-det-date"><ClientTimestamp timestamp={createdTs} format="MMM Do, YYYY" sameYearFormat="MMM Do" />&nbsp;</Link>
        </div>
        {!!commentCount && (
          <div className="spost-card-foot-com">
            {commentCount} <Icon.MessageBubbleEmpty />
          </div>
        )}
      </div>
    );
  }

  renderPrompt() {
    const {className} = this.props;
    return (
      <div className={`spost-card ${className}`} ref={this.refCard}>
        <Prompt onWantsCreate={this.props.onWantsCreate} />
      </div>
    );
  }

  renderSkeleton() {
    const variant = this.skeleton;
    const text = (() => {
      if (variant === 1) return (<>
        <FakeLines style="users" count={1} />
        <FakeLines count={2} lineHeight={5} spaceHeight={12} />
      </>);
      if ([2,3].includes(variant)) return (<>
        <FakeLines style="users" count={1} />
      </>);
      if (variant === 4) return (<>
        <FakeLines style="users" count={1} />
        <FakeLines count={3} />
      </>);
    })();
    return (
      <div className={`spost-card spskel variant-${variant}`} ref={this.refCard}>
        <div className="spskel-img"></div>
        <div className="spskel-text">
          {text}
        </div>
      </div>
    );
  }

  renderReactions() {
    const {onCalcHeight} = this.props;
    const {post} = this;
    if (onCalcHeight) return null;
    if (!this.isActual) return null;
    if (!post?.id) return null;
    return <ReactionSet targetType={ReactionTargets.POST} targetId={post.id} count={5} className="spost-card-react" />;
  }

  render() {
    const {className, listing} = this.props;
    const {post} = this;
    if (this.isDeleted) return null;
    if (this.isPrompt) return this.renderPrompt();
    if (this.skeleton) return this.renderSkeleton();
    if (!post) return null;
    const imgPath = (post.imgPaths || [])[0];
    const hasBody = !!(post.body || '').trim();
    const urlObj = (() => {
      if (imgPath) return null;
      return post.previewUrlObj || null;
    })();
    const showCarousel = (post.imgPaths?.length || 0) > 1;

    return (
      <div className={`spost-card ${className}`} ref={this.refCard}>
        <div onClick={this.onClickCard} className="spost-card-click">
          <div className="spost-card-main">
            {imgPath && (
              <img src={cdn.imgUrl(imgPath, {width: 592, height: 1184})} className="spost-card-img" onLoad={this.onLoadImg} />
              // <img src={cdn.imgUrl(imgPath, {width: 762, height: 1184})} className="spost-card-img" onLoad={this.onLoadImg} onError={this.onErrorImg} ref={this.refImg} />
            )}
            {showCarousel && (
              <div className="spost-card-carousel">
                {post.imgPaths.map((imgPath) => {
                  return <img key={imgPath} src={cdn.imgUrl(imgPath, {height: 60, width: 120})} className="spost-card-carousel-img" />;
                })}
                <div className="spost-card-carousel-count">{`+${post.imgPaths.length - 1}`}</div>
              </div>
            )}
            {urlObj && (
              <UrlPreview urlObj={urlObj} />
            )}
            {hasBody && (
              <div className={`spost-card-body ${this.largeText ? 'large' : 'small'}`}>
                <Text.Paragraphs str={post.body} pClassName="spost-card-body-p" />
              </div>
            )}
          </div>
          {this.renderFoot()}
        </div>
        {this.renderReactions()}
      </div>
    );
  }

}

PostCard.propTypes = {
  id: PropTypes.string.isRequired,
  onCalcHeight: PropTypes.func,
  className: PropTypes.string,
  onWantsCreate: PropTypes.func,
};

PostCard.defaultProps = {
  onCalcHeight: null,
  className: '',
  onWantsCreate: noop,
};

const stateToProps = (_state, ownProps) => {
  const listingSlx = SocialDuck.Slx.listingSlx(ownProps.id);
  return (state) => ({
    listing: listingSlx(state),
  });
};

const dispatchToProps = (dispatch) => ({
  openModal: (postId, listingId) => dispatch(ViewDuck.Ax.openModal(postId, listingId))
})

export default connect(stateToProps, dispatchToProps)(PostCard);
