import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';

import Icon          from 'app/components/common/icon';
import Masonry       from 'app/components/social/masonry';
import {
  SocialFeedTypes as FeedTypes,
}                    from 'app/constants';
import ModalPostDuck from 'app/ducks/modal-social-post-form';
import Duck          from 'app/ducks/social';
import cdn           from 'app/helpers/cdn';

const headerThreshold = 1;

class SocialFeed extends React.PureComponent {

  constructor(props) {
    super(props);

    this.refInlineHeader = React.createRef();
    this.refStaticHeader = React.createRef();

    this.state = {
      ids: null,
      moreCount: null,
      isLoading: false,
      // showStaticHeader: false,
    };

    this.onWantsCreate = this.onWantsCreate.bind(this);
    this.onCreate = this.onCreate.bind(this);
    this.onClickLoadMore = this.onClickLoadMore.bind(this);
    // this.onHeaderObserverChange = this.onHeaderObserverChange.bind(this);
  }

  componentDidMount() {
    if (this.props.loadInitial) this.onClickLoadMore();
  }

//   componentDidMount() {
//     this.observer = new IntersectionObserver(this.onHeaderObserverChange, {threshold: headerThreshold});
//     this.observer.observe(this.refInlineHeader.current);
//   }
// 
//   componentWillUnmount() {
//     this.observer.disconnect();
//   }

  get moreCount() {
    return this.state.moreCount != null
      ? this.state.moreCount
      : this.props.initialMoreCount;
  }

  get lastId() {
    return _.last(this.getRealIds());
  }

  get showFirstPrompt() {
    return !this.getRealIds().length;
  }

  get allowCreate() {
    if (this.props.editMode) return false;
    return true;
  }

  getRealIds(stateIds=this.state.ids) {
    return stateIds || this.props.initialIds || [];
  }

  getDisplayIds(stateIds=this.state.ids) {
    const realIds = this.getRealIds(stateIds);
    const promptIds = this.allowCreate ? ['prompt'] : [];

    // edit mode - just show skeletons with no prompt
    if (this.props.editMode) {
      return ['variant-1-a', 'variant-4-a', 'variant-3-a', 'variant-2-a', 'variant-2-b', 'variant-4-b'];
    }
    // empty state
    if (!realIds.length) {
      return [...promptIds, 'variant-1-a', 'variant-2-a', 'variant-3-a', 'variant-4-a']
    }
    // normal state
    return [...promptIds, ...realIds];
  }

  async onClickLoadMore() {
    const beforeListingId = this.lastId;
    this.setState({isLoading: true});
    const {moreCount, socialPostListings: listings} = await this.props.search({beforeListingId});
    const ids = listings.map(l => l.id);
    this.setState((prevState) => {
      const prevIds = this.getRealIds(prevState.ids);
      return {
        isLoading: false,
        ids: [...prevIds, ...ids],
        moreCount,
      }
    });
  }

  // onHeaderObserverChange([entry]) {
  //   const isVisible = entry.intersectionRatio >= headerThreshold;
  //   const pastTop = entry.boundingClientRect.y <= 0;
  //   const showStaticHeader = !isVisible && pastTop;
  //   this.setState({showStaticHeader});
  // }

  checkHeader() {
    const realEl = this.refInlineHeader.current;
    console.log('checkHeader');
  }

  onWantsCreate() {
    const {type: feedType, id: feedId, openCreateModal, editMode} = this.props;
    if (editMode) return;
    openCreateModal({feedType, feedId, onCreate: this.onCreate});
  }

  onCreate(listing) {
    this.setState((prevState) => {
      return {
        ids: [listing.id, ...this.getRealIds(prevState.ids)],
      };
    });
  }

  renderHeader(isStatic=false) {
    return null;
    const {title, imgPath} = this.props;
    const {showStaticHeader} = this.state;
    const staticShowClass = isStatic ? (showStaticHeader ? 'show' : 'hide') : '';
    return (
      <div className={`sfeed-header-con static-${isStatic} ${staticShowClass}`} ref={isStatic ? this.refStaticHeader : this.refInlineHeader}>
        <div className="sfeed-header">
          <div className="sfeed-header-widther">
            {!!imgPath && (
              <img src={cdn.imgUrl(imgPath, {width: 300, height: 148})} alt={title} className="sfeed-header-img" />
            )}
            <div className="sfeed-header-text">
              <div className="sfeed-header-text-title">{title}</div>
              <div className="sfeed-header-text-subtitle">Activity Feed</div>
            </div>
            <div className="sfeed-header-actions">
              <button className="btn icon" onClick={this.onWantsCreate}><Icon.SendEmail /> Post</button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const {className, title} = this.props;
    const isExhausted = !this.moreCount;

    return (
      <div className={`sfeed ${isExhausted ? 'exhausted' : 'has-more'} ${className}`}>
        {this.renderHeader()}
        {this.renderHeader(true)}
        {this.showFirstPrompt && (
          <p className="sfeed-first-prompt">{`Share photos or messages on ${title || 'the feed'}:`}</p>
        )}
        <div className={`sfeed-masonry`}>
          <Masonry listingIds={this.getDisplayIds()} onWantsCreate={this.allowCreate ? this.onWantsCreate : undefined} />
          {!!this.moreCount && (
            <div className="sfeed-masonry-controls">
              <button onClick={this.onClickLoadMore} className="btn secondary small">Load More</button>
            </div>
          )}
        </div>
      </div>
    );
  }

}

SocialFeed.propTypes = {
  type: PropTypes.oneOf(Object.values(FeedTypes)).isRequired,
  id: PropTypes.string.isRequired,
  initialIds: PropTypes.arrayOf(PropTypes.string),
  initialMoreCount: PropTypes.number,
  title: PropTypes.string.isRequired,
  imgPath: PropTypes.string,
  loadMoreLimit: PropTypes.number,
  className: PropTypes.string,
  editMode: PropTypes.bool,
  loadInitial: PropTypes.bool,
};

SocialFeed.defaultProps = {
  initialIds: [],
  initialMoreCount: 0,
  loadMoreLimit: 30,
  className: '',
  editMode: false,
  loadInitial: false,
};

const stateToProps = (state) => ({

});

const dispatchToProps = (dispatch, ownProps) => ({
  openCreateModal: (params) => dispatch(ModalPostDuck.Ax.open(params)),
  search: (params={}) => {
    const {type: feedType, id: feedId} = ownProps;
    const limit = ownProps.loadMoreLimit || SocialFeed.defaultProps.loadMoreLimit;
    return dispatch(Duck.Ax.listingsSearch({feedType, feedId, limit, ...params}))
  },
});

export default connect(stateToProps, dispatchToProps)(SocialFeed);
