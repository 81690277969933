import _ from 'lodash';

import { Types } from 'app/actions/company-admin/page-dashboard-view';
import utils from 'app/reducers/utils';

const initialState = {
  companyDashboardId: null,
  loadPending: false,
  socialListingIds: [],
  socialMoreCount: 0,
};

const reducer = utils.createReducer(initialState, {

  [`${Types.LOAD}_PENDING`]: (state, {companyDashboardId}) => {
    return {...state,
      companyDashboardId,
      loadPending: true,
    };
  },
  [`${Types.LOAD}_RESOLVED`]: (state, {companyDashboardId, result}) => {
    if (companyDashboardId !== state.companyDashboardId) return state;
    return {...state,
      loadPending: false,
      socialListingIds: result[3].socialPostListings.map(l => l.id),
      socialMoreCount: result[3].moreCount,
    };
  },

});

export default reducer;
