import PropTypes from 'prop-types';
import React from 'react';

import DateRangePicker  from 'app/components/common/date-range-picker';
import Icon             from 'app/components/common/icon';
import Link             from 'app/components/common/link';
import SectionCampaigns from 'app/components/company-dashboards/section-campaigns'
import SectionCommunity from 'app/components/company-dashboards/section-community';
import SectionGift      from 'app/components/company-dashboards/section-gift';
import SectionGrant     from 'app/components/company-dashboards/section-grant';
import SectionMatch     from 'app/components/company-dashboards/section-match';
import SectionSocial    from 'app/components/company-dashboards/section-social';
import SectionTopStats  from 'app/components/company-dashboards/section-top-stats';
import SectionVolunteer from 'app/components/company-dashboards/section-volunteer';
import config           from 'app/config';
import companiesHelpers from 'app/helpers/companies';
import AuthSlx          from 'app/selectors/auth';

class CompanyDashboard extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onSelectDateRange = this.onSelectDateRange.bind(this);
  }

  onSelectDateRange({startDateStr: startDate, endDateStr: endDate}) {
    this.props.onChangeDateRange && this.props.onChangeDateRange({startDate, endDate});
  }

  renderMission({company, dashboard}) {
    const showExGmLink = (dashboard.id === '22dff646-f1e0-4870-af9b-4788bf907cc1') && (Math.round(new Date().getTime() / 1000) < 1733811703);
    const showAttnGmLink = (dashboard.id === '05ae745c-bd1f-4d06-a801-de79895d505c') && (Math.round(new Date().getTime() / 1000) < 1735707600);
    const showDomeGmLink = (dashboard.id === '5cbff767-eed2-4b89-a3a9-5522cede9b5b') && (Math.round(new Date().getTime() / 1000) < 1735707600);
    const logoUrl = dashboard.logoPath
      ? encodeURI(`${config.s3BaseUrl}/${dashboard.logoPath}`)
      : company.logoUrl;
    return (
      <div className="cdash-mission">
        {logoUrl && (
          <img className="cdash-mission-logo" src={logoUrl} alt={company.name} />
        )}
        {!!dashboard.missionText && (
          <div className="cdash-mission-blurb">
            {dashboard.missionText.split('\n').map((p, i) => <p key={i}>{p}</p>)}
          </div>
        )}
        {showExGmLink && (
          <Link href="/brackets/78858026-af91-4835-9abb-4b1f990b45f2" style={{margin: '48px auto', display: 'block', width: 340}} className="btn icon special black"><Icon.Bracket />Play Giving Madness Bracket</Link>
        )}
        {showAttnGmLink && (
          <Link href="/brackets/ccdfdea0-6dd6-4431-9a7a-bbca355592d3" style={{margin: '48px auto', display: 'block', width: 340}} className="btn icon special black"><Icon.Bracket />Play Giving Madness Bracket</Link>
        )}
        {showDomeGmLink && (
          <Link href="/brackets/f281f50a-9191-485a-948c-5d9f5efbc431" style={{margin: '48px auto', display: 'block', width: 340}} className="btn icon special black"><Icon.Bracket />Play Giving Madness Bracket</Link>
        )}
      </div>
    );
  }

  renderDateRange(company, data) {
    const currentFiscalYear = companiesHelpers.getCurrentFiscalYear(company);
    return (
      <div className="cdash-year">
        <DateRangePicker
          onSelect={this.onSelectDateRange}
          currentFiscalYear={currentFiscalYear}
          startDateStr={data.startDate}
          endDateStr={data.endDate}
        />
      </div>
    );
  }

  render() {
    const { isLoading, company, dashboard, data, editMode, socialListingIds, socialMoreCount } = this.props;
    const showLoading = isLoading || !company || !dashboard || !data;
    return (
      <div className="cdash">
        <div className="cdash-widther">
          {showLoading ? (
            <Icon.Loading className="cdash-loading" />
          ) : (<>
            {this.renderMission({company, dashboard})}
            {this.renderDateRange(company, data)}
            <SectionTopStats {...{dashboard, data}} />
            <SectionCampaigns {...{dashboard}} />
            <SectionSocial  {...{dashboard, company, editMode, socialListingIds, socialMoreCount}} />
            <SectionMatch {...{dashboard, data}} />
            <SectionGrant />
            <SectionGift {...{dashboard, data}} />
            <SectionVolunteer {...{dashboard, data}} />
            <SectionCommunity {...{company, dashboard, data}} />
          </>)}
        </div>
      </div>
    );
  }

}

CompanyDashboard.propTypes = {
  onChangeDateRange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  company: PropTypes.object,
  dashboard: PropTypes.object,
  data: PropTypes.object,
  editMode: PropTypes.bool,
  socialListingIds: PropTypes.arrayOf(PropTypes.string),
  socialMoreCount: PropTypes.number,
};

CompanyDashboard.defaultProps = {
  isLoading: false,
  editMode: false,
  socialListingIds: [],
  socialMoreCount: 0,
};

export default CompanyDashboard;
