import PropTypes from 'prop-types';
import React from 'react';

import CadminApi        from 'app/apis/company-admin';
import RequireBackstage from 'app/components/gating/require-backstage';
import Bracket          from 'app/components/madness/bracket';
import Countdown        from 'app/components/madness/countdown';
import Divvy            from 'app/components/madness/divvy';
import Legend           from 'app/components/madness/legend';
import Header           from 'app/components/madness/header';
import Feed             from 'app/components/social/feed';
import {
  SocialFeedTypes,
}                       from 'app/constants';
import history          from 'app/history';

class BracketProfile extends React.PureComponent {

  onClickCrazy(event) {
    const { bracket } = this.props;
    if (!confirm('Are you sure? This will clear existing votes and winners and restart the bracket.')) return null;
    window.scrollTo(0,0);
    CadminApi.bracketsSetCrazy(bracket.companyId, bracket.id).then(() => {
      history.millieRefresh();
    })
  }

  render() {
    const { bracket, voteRecord, socialListingIds, socialMoreCount, editMode } = this.props;

    return (
      <div className="gm-profile">
        <Header bracket={bracket} voteRecord={voteRecord} />
        <Divvy bracket={bracket} />
        <Bracket bracket={bracket} />
        <Legend />
        {bracket.hasSocialFeed && (
          <div className="gm-profile-social">
            <h2 className="gm-profile-social-heading">Activity Feed</h2>
            <Feed
              type={SocialFeedTypes.BRACKET}
              id={bracket.id}
              initialIds={socialListingIds || []}
              initialMoreCount={socialMoreCount || 0}
              title={bracket.name}
              className="gm-profile-social-feed"
              editMode={editMode}
            />
          </div>
        )}
        <RequireBackstage>
          {['upcoming', 'complete'].includes(bracket.status) && (<>
            <br /><br /><br /><br />
            <button className="btn special sunrise" style={{margin: '0 auto', display: 'block'}} onClick={this.onClickCrazy.bind(this)}>Do it Now</button>
            <br /><br /><br /><br />
          </>)}
        </RequireBackstage>
      </div>
    );
  }

}

BracketProfile.propTypes = {
  bracket: PropTypes.object.isRequired,
  voteRecord: PropTypes.object.isRequired,
  socialListingIds: PropTypes.arrayOf(PropTypes.string),
  socialMoreCount: PropTypes.number,
  editMode: PropTypes.bool,
};

BracketProfile.defaultProps = {
  socialListingIds: null,
  socialMoreCount: null,
  editMode: false,
};

export default BracketProfile;
