import _ from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import React from 'react';
import { connect } from 'react-redux';

// import PageAx             from 'app/actions/page-bracket';
import Icon               from 'app/components/common/icon';
import Link               from 'app/components/common/link';
import Meta               from 'app/components/common/meta';
import ErrorPage          from 'app/components/errors/page';
import MainLayout         from 'app/components/layout/main-layout';
import PageLoading        from 'app/components/layout/page-loading';
import BracketProfile     from 'app/components/madness/bracket-profile';
import confetti           from 'app/confetti';
import config             from 'app/config';
import {
  BracketStatuses,
}                         from 'app/constants';
import history            from 'app/history';
import paths              from 'app/paths';
import PageSlx            from 'app/selectors/page-bracket';

const shownConfetti = {};

class PageBracket extends React.PureComponent {

  componentDidMount() {
    setTimeout(() => {
      const isComplete = this.props.bracket?.status === BracketStatuses.COMPLETE;
      if (isComplete && !shownConfetti[this.props.bracket.id]) {
        shownConfetti[this.props.bracket.id] = true;
        confetti.fireworks(4);        
      }
    }, 500);
  }

  render() {
    const { loadPending, bracket, voteRecord, socialListingIds, socialMoreCount } = this.props;
    if (!bracket || !voteRecord) {
      return loadPending
        ? <PageLoading />
        : <ErrorPage statusCode={404} />;
    }

    return (
      <MainLayout>
        <Meta title={`${bracket.name} | Millie`} />
        <BracketProfile bracket={bracket} voteRecord={voteRecord} socialListingIds={socialListingIds} socialMoreCount={socialMoreCount} />
      </MainLayout>
    );
  }

}

const stateToProps = (state) => ({
  loadPending: PageSlx.loadPending(state),
  bracket: PageSlx.bracket(state),
  voteRecord: PageSlx.voteRecord(state),
  socialListingIds: PageSlx.socialListingIds(state),
  socialMoreCount: PageSlx.socialMoreCount(state),
});

const dispatchToProps = (dispatch) => ({
});

export default connect(stateToProps, dispatchToProps)(PageBracket);
